<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>API Maker Features</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-testing.png" alt="Inbuilt Testing" title="Inbuilt Testing">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<div class="inner-mid-section">
    <div class="container">
        <!-- back page buuton start -->
        <div class="col-12 mt-2 mb-4 text-left">
            <div class="backpage-btn">
                <a class="d-flex flex-nowrap justify-content-start align-items-center" routerLink="/features"><span
                        class="d-flex pe-2"><i class="fa-solid fa-arrow-left arrow-icon"></i></span><span
                        class="d-flex">Back</span></a>
            </div>
        </div>
        <!-- back page buuton End -->
        <!-- page title start -->
        <h2>Inbuilt Testing <span class="light-text">Framework</span></h2>
        <!-- page title End -->
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="row d-flex justify-content-start align-items-start flex-direction">
                    <!------------------------------------------------------------------------------ left start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-9 col-lg-8 col-md-12 col-sm-12 left-section">
                        <!-- content start -->
                        <div class="inn-common-content mb-4">
                            <h3 class="mb-3">Powerful inbuilt testing framework provided for business logic testing</h3>
                            <h4 class="mb-3 pt-3">In our typical banking system, we can have the below type of test
                                cases.</h4>
                            <h5 class="mb-3 pt-3">Interest Calculation</h5>
                            <div class="common-listing">
                                <ul>
                                    <li>Saving Account interest calculation</li>
                                    <li>Current Account interest calculation</li>
                                    <li>Joint Account interest calculation</li>
                                    <li>Negative Scenario of interest calculation</li>
                                </ul>
                            </div>
                            <h5 class="mb-3 pt-5">Money Transfer</h5>
                            <div class="common-listing">
                                <ul>
                                    <li>Transfer from saving to current & apply charges</li>
                                    <li>Saving to UPI receiver transfer</li>
                                    <li>Current to Saving account transfer</li>
                                </ul>
                            </div>
                            <div class="inner-page-cnt-banner inner-small-banner mt-4 mb-4 text-center"><img
                                    src="../../assets/images/testing-content-banner.png" alt="Test framework" title="Test framework">
                            </div>
                            <div class="common-listing mt-4">
                                <ul>
                                    <li>API Maker has inbuilt 8000+ test cases to make the core part rock solid.</li>
                                    <li>Developers can write test cases for custom APIs, and utility classes. Which has
                                        actual business logic and complex calculations.</li>
                                    <li>Inbuilt testing framework can easily mock any API calls with intuitive UI
                                        design, and if required we can do actual API calls also.</li>
                                    <li>On test case failure, show proper expected and received values in test cases.
                                    </li>
                                    <li>If the test case fails, it will properly highlight the code line and its entire
                                        call stack, so we can easily find bugs in custom code.</li>
                                    <li>Each developer can run test cases on their own, so they will run test cases
                                        before starting their work, after work is done and before code push, so business
                                        logic will never break.</li>
                                    <li>All test cases are divided in test suites, we can have N number of test suites
                                        and N number of test cases in it. While running we can select a specific test
                                        suite to run test cases, and avoid other test suites which are not relevant to
                                        the current work of specific developer.</li>
                                    <li>We can enforce every developer to write test cases of their code.</li>
                                    <li>So, by using API Maker inbuilt testing framework, we can build a very powerful
                                        and stable and low maintenance system with very ease.</li>
                                </ul>
                            </div>
                        </div>
                        <!-- content End -->
                    </div>
                    <!------------------------------------------------------------------------------ left End --------------------------------------------------------------------------------------->

                    <!------------------------------------------------------------------------------ sidebar start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-12 col-sm-12 right-section">
                        <app-features-sidebar></app-features-sidebar>
                    </div>
                    <!------------------------------------------------------------------------------ sidebar End --------------------------------------------------------------------------------------->
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>