<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>API Maker Features</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-memory-footprint.png" alt="Auto Generated APIs"
                                title="Auto Generated APIs">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<div class="inner-mid-section">
    <div class="container">
        <!-- back page buuton start -->
        <div class="col-12 mt-2 mb-4 text-left">
            <div class="backpage-btn">
                <a class="d-flex flex-nowrap justify-content-start align-items-center" routerLink="/features"><span
                        class="d-flex pe-2"><i class="fa-solid fa-arrow-left arrow-icon"></i></span><span
                        class="d-flex">Back</span></a>
            </div>
        </div>
        <!-- back page buuton End -->
        <!-- page title start -->
        <h2>Low Memory <span class="light-text">Footprint</span></h2>
        <!-- page title End -->
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="row d-flex justify-content-start align-items-start flex-direction">
                    <!------------------------------------------------------------------------------ left start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-9 col-lg-8 col-md-12 col-sm-12 left-section">
                        <!-- content start -->
                        <div class="inn-common-content mb-4">
                            <h3 class="mb-3">pm2 list</h3>
                            <div class="col-12  text-center"><img src="../../assets/images/memory-content-banner.png"
                                    alt="140MB - API Maker - Low Memory footprint"
                                    title="140MB - API Maker - Low Memory footprint"></div>
                            <div class="common-listing mt-4">
                                <ul>
                                    <li>API Maker process takes around ~140MB, (including all native code) so we
                                        have lots of memory available on server of 1GB RAM, to do amazing tasks.
                                    </li>
                                    <li>API Maker internally doing lazy loading of modules, so if the project is
                                        using only MySQL, it will not load drivers of other databases. So it can
                                        operate on low memory.</li>
                                    <li>API Maker provides high performance with advanced features stream APIs which
                                        are capable of getting a huge amount of data from the database.</li>
                                </ul>
                            </div>
                            <div class="col-12 mt-3 text-center"><img src="../../assets/images/memory-content-banner-2.svg"
                                    alt="API Maker Low Memory footprint" title="API Maker Low Memory footprint">
                            </div>
                            <div class="common-listing mt-4">
                                <ul>
                                    <li>So, if we want to get 10GB of data from database servers, API Maker’s server
                                        having 1GB RAM can easily do it.</li>
                                    <li>While doing that it will not block any other operations and API calls.</li>
                                    <li>We will not see any spike in memory usage because it will get data in small
                                        chunks from the database server and send it to the user.</li>
                                    <li>Streaming APIs and file operations, are very useful in long-running
                                        schedulers and data migration tasks, or sending many emails to users.</li>
                                    <li>As you can see in the above image, API Maker runs on small memory footprint
                                        and a single server can handle many components.</li>
                                    <li>They all together can handle ~700+ Req/s and ~2.5M+ Req/hour, which is ~25%
                                        of total UPI transactions of August 2022 in India which is ~9.1M Req/hour.
                                        API Maker benchmark payload is benchmark_dataset.json, and Linode VPS 1vCPU
                                        1GB RAM server.</li>
                                </ul>
                            </div>
                        </div>
                        <!-- content End -->
                    </div>
                    <!------------------------------------------------------------------------------ left End --------------------------------------------------------------------------------------->

                    <!------------------------------------------------------------------------------ sidebar start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-12 col-sm-12 right-section">
                        <app-features-sidebar></app-features-sidebar>
                    </div>
                    <!------------------------------------------------------------------------------ sidebar End --------------------------------------------------------------------------------------->
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>