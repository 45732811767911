<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>Privacy Policy</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-privacy-policy.png" alt="Privacy Policy"
                                title="Privacy Policy">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<!-- inner start -->
<div class="inner-mid-section">
    <div class="container">
        <h2 class="text-center">Privacy Policy for <span class="light-text">API Maker.</span></h2>
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="inn-common-content mb-4">
                    <div class="w-100 mb-3">At API Maker, accessible from https://apimaker.dev, one of our main
                        priorities is the
                        privacy of our visitors. This Privacy Policy document contains types of information that is
                        collected and recorded by API Maker and how we use it.</div>
                    <div class="w-100 mb-3">If you have additional questions or require more information about our
                        Privacy Policy, do not hesitate to contact us.</div>
                    <div class="w-100 mb-3">This Privacy Policy applies only to our online activities and is valid
                        for visitors to our website about the information that they shared and/or collect in API
                        Maker. This policy does not apply to any information collected offline or via channels other
                        than this website.</div>
                    <h2 class="mt-4 mb-2">Consent</h2>
                    <div class="w-100 mb-2">
                        By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                    </div>
                    <h2 class="mt-4 mb-2">Information we collect</h2>
                    <div class="w-100 mb-3">The personal information that you are asked to provide, and the reasons
                        why you are asked to provide it, will be made clear to you at the point we ask you to
                        provide your personal information.</div>
                    <div class="w-100 mb-3">If you contact us directly, we may receive additional information about
                        you such as your name, email address, phone number, the contents of the message and/or
                        attachments you may send us, and any other information you may choose to provide.</div>
                    <div class="w-100 mb-2">When you register for an Account, we will ask for your contact
                        information, including items such as name, company name, address, email address, and
                        telephone number, which will be used to issue a license for the product.</div>
                    <h2 class="mt-4 mb-2">How we use your information</h2>
                    <div class="w-100 mb-2">We use the information we collect in various ways, including :</div>
                    <div class="common-listing">
                        <ul>
                            <li>Provide, operate, and maintain our website</li>
                            <li>Improve, personalize, and expand our website</li>
                            <li>Understand and analyze how you use our website</li>
                            <li>Develop new products, services, features, and functionality</li>
                            <li>Communicate with you, either directly or through one of our partners, including for
                                customer service, to provide you with updates and other information relating to the
                                website, and for marketing and promotional purposes</li>
                            <li>Send you emails</li>
                            <li>Find and prevent fraud</li>
                        </ul>
                    </div>
                    <h2 class="mt-4 mb-2">Log Files</h2>
                    <div class="w-100 mb-2">API Maker follows a standard procedure of using log files. These files
                        log visitors when they visit websites. All hosting companies do this and are a part of
                        hosting services' analytics. The information collected by log files includes internet
                        protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp,
                        referring/exit pages, and possibly the number of clicks. These are not linked to any
                        personally identifiable information. The purpose of the information is for analyzing trends,
                        administering the site, tracking users' movement on the website, and gathering demographic
                        information.</div>
                    <h2 class="mt-4 mb-2">Cookies and Web Beacons</h2>
                    <div class="w-100 mb-2">Like any other website, API Maker uses 'cookies'. These cookies are used
                        to store information including visitors' preferences, and the pages on the website that the
                        visitor accessed or visited. The information is used to optimize the users' experience by
                        customizing our web page content based on visitors' browser type and/or other information.
                    </div>
                    <h2 class="mt-4 mb-2">Google DoubleClick DART Cookie</h2>
                    <div class="w-100 mb-2">Google is one of the third-party vendors on our site. It also uses
                        cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to
                        www.website.com and other sites on the internet. However, visitors may choose to decline the
                        use of DART cookies by visiting the Google ad and content network Privacy Policy at the
                        following URL – <a href="https://policies.google.com/technologies/ads"
                            target="_blank">https://policies.google.com/technologies/ads</a></div>
                    <h2 class="mt-4 mb-2">Advertising Partners Privacy Policies</h2>
                    <div class="w-100 mb-2">You may consult this list to find the Privacy Policy for each of the
                        advertising partners of API Maker.
                        Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web
                        Beacons that are used in their respective advertisements and links that appear on API Maker,
                        which are sent directly to users' browser. They automatically receive your IP address when
                        this occurs. These technologies are used to measure the effectiveness of their advertising
                        campaigns and/or to personalize the advertising content that you see on websites that you
                        visit.</div>
                    <div class="w-100 mb-2">Note that API Maker has no access to or control over these cookies that
                        are used by third-party advertisers.</div>
                    <h2 class="mt-4 mb-2">Third-Party Privacy Policies</h2>
                    <div class="w-100 mb-2">API Maker's Privacy Policy does not apply to other advertisers or
                        websites. Thus, we are advising you to consult the respective Privacy Policies of these
                        third-party ad servers for more detailed information. It may include their practices and
                        instructions about how to opt out of certain options.</div>
                    <div class="w-100 mb-2">You can choose to disable cookies through your individual browser
                        options. To know more detailed information about cookie management with specific web
                        browsers, it can be found on the browsers' respective websites.</div>
                    <h2 class="mt-4 mb-2">CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
                    <div class="w-100 mb-2">Under the CCPA, among other rights, California consumers have the right
                        to:</div>
                    <div class="w-100 mb-2">Request that a business that collects a consumer's personal data
                        disclose the categories and specific pieces of personal data that a business has collected
                        about consumers.</div>
                    <div class="w-100 mb-2">Request that a business deletes any personal data about the consumer
                        that a business has collected.</div>
                    <div class="w-100 mb-2">Request that a business that sells a consumer's personal data, not sell
                        the consumer's personal data.</div>
                    <div class="w-100 mb-2">If you make a request, we have one month to respond to you. If you would
                        like to exercise any of these rights, please contact us.</div>
                    <h2 class="mt-4 mb-2">GDPR Data Protection Rights</h2>
                    <div class="w-100 mb-2">We would like to make sure you are fully aware of all of your data
                        protection rights. Every user is entitled to the following:</div>
                    <div class="w-100 mb-2">The right to access – You have the right to request copies of your
                        personal data. We may charge you a small fee for this service.</div>
                    <div class="w-100 mb-2">The right to rectification – You have the right to request that we
                        correct any information you believe is inaccurate. You also have the right to request that
                        we complete the information you believe is incomplete.</div>
                    <div class="w-100 mb-2">The right to erasure – You have the right to request that we erase your
                        personal data, under certain conditions.</div>
                    <div class="w-100 mb-2">The right to restrict processing – You have the right to request that we
                        restrict the processing of your personal data, under certain conditions.</div>
                    <div class="w-100 mb-2">The right to object to processing – You have the right to object to our
                        processing of your personal data, under certain conditions.</div>
                    <div class="w-100 mb-2">The right to data portability – You have the right to request that we
                        transfer the data that we have collected to another organization, or directly to you, under
                        certain conditions.</div>
                    <div class="w-100 mb-2">If you make a request, we have one month to respond to you. If you would
                        like to exercise any of these rights, please contact us.</div>
                    <h2 class="mt-4 mb-2">Children's Information</h2>
                    <div class="w-100 mb-2">Another part of our priority is adding protection for children while
                        using the internet. We encourage parents and guardians to observe, participate in, and/or
                        monitor and guide their online activity.</div>
                    <div class="w-100 mb-2">API Maker does not knowingly collect any Personal Identifiable
                        Information from children under the age of 13. If you think that your child provided this
                        kind of information on our website, we strongly encourage you to contact us immediately and
                        we will do our best efforts to promptly remove such information from our records.</div>
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>