 <!-- header start -->
 <div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>API Maker Features</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-systems.png" alt="Inbuilt System APIs"
                                title="Inbuilt System APIs">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<div class="inner-mid-section">
    <div class="container">
        <!-- back page buuton start -->
        <div class="col-12 mt-2 mb-4 text-left">
            <div class="backpage-btn">
                <a class="d-flex flex-nowrap justify-content-start align-items-center" routerLink="/features"><span
                        class="d-flex pe-2"><i class="fa-solid fa-arrow-left arrow-icon"></i></span><span
                        class="d-flex">Back</span></a>
            </div>
        </div>
        <!-- back page buuton End -->
        <!-- page title start -->
        <h2>Inbuilt <span class="light-text">System APIs</span></h2>
        <!-- page title End -->
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="row d-flex justify-content-start align-items-start flex-direction">
                    <!------------------------------------------------------------------------------ left start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-9 col-lg-8 col-md-12 col-sm-12 left-section">
                        <div class="col-12 mb-4">
                            <h3>API Maker® provides intellectual system APIs to do your work smartly.</h3>
                        </div>
                        <!-- content start -->
                        <div class="inn-common-content mb-4">
                            <div class="row mt-4 pt-3">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex system-icon">
                                                        <img src="../../assets/images/system-icon-1.png"
                                                            alt="Encrypt data Decrypt data Hash data"
                                                            title="Encrypt data Decrypt data Hash data">
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <div class="common-listing">
                                                            <ul>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-encrypt-data-api.html"
                                                                        target="_blank">Encrypt data</a></li>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-decrypt-data-api.html"
                                                                        target="_blank">Decrypt data</a></li>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-hash-data-api.html"
                                                                        target="_blank">Hash data</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex system-icon">
                                                        <img src="../../assets/images/system-icon-2.png"
                                                            alt="Get Secret key/keys" title="Get Secret key/keys">
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <div class="common-listing">
                                                            <ul>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-get-secret-by-name-api.html"
                                                                        target="_blank">Get Secret key/keys</a>
                                                                </li>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-get-redis-key-api.html"
                                                                        target="_blank">Get redis key/keys</a>
                                                                </li>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-set-redis-key-api.html"
                                                                        target="_blank">Set redis key/keys</a>
                                                                </li>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-remove-redis-key-api.html"
                                                                        target="_blank">Remove redis key/keys</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex system-icon">
                                                        <img src="../../assets/images/features-redis.png"
                                                            alt="Reset database cache" title="Reset database cache">
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <div class="common-listing">
                                                            <ul>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-reset-redis-cache-db-api.html"
                                                                        target="_blank">Reset database cache</a>
                                                                </li>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-reset-redis-cache-third-party-api.html"
                                                                        target="_blank">Reset third party API
                                                                        cache</a></li>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-reset-redis-cache-custom-api.html"
                                                                        target="_blank">Reset custom apis
                                                                        cache</a></li>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-reset-redis-cache-system-api.html"
                                                                        target="_blank">Reset system apis
                                                                        cache</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex system-icon">
                                                        <img src="../../assets/images/system-icon-4.png"
                                                            alt="Is valid data for table"
                                                            title="Is valid data for table">
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <div class="common-listing">
                                                            <ul>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-is-valid-data-for-table-api.html"
                                                                        target="_blank">Is valid data for
                                                                        table</a></li>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-is-valid-data-for-custom-api.html"
                                                                        target="_blank">Is valid data for custom
                                                                        API</a></li>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-is-valid-data-for-third-party-api.html"
                                                                        target="_blank">Is valid data for third
                                                                        party api</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex system-icon">
                                                        <img src="../../assets/images/system-icon-5.png" alt="Emit event"
                                                            title="Emit event">
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <div class="common-listing">
                                                            <ul>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-emit-event-api.html"
                                                                        target="_blank">Emit event</a></li>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-emit-event-ws-api.html"
                                                                        target="_blank">Emit event WS</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex system-icon">
                                                        <img src="../../assets/images/system-icon-6.png" alt="Emit event"
                                                            title="Emit event">
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <div class="common-listing">
                                                            <ul>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-token-api.html"
                                                                        target="_blank">Get token</a></li>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-call-external-api.html"
                                                                        target="_blank">Call external API</a></li>
                                                                <li><a href="https://docs.apimaker.dev/v1/docs/apis-all/system-apis/system-generated-get-table-meta-api.html"
                                                                        target="_blank">Get table meta data</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <!-- content End -->
                    </div>
                    <!------------------------------------------------------------------------------ left End --------------------------------------------------------------------------------------->

                    <!------------------------------------------------------------------------------ sidebar start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-12 col-sm-12 right-section">
                        <app-features-sidebar></app-features-sidebar>
                    </div>
                    <!------------------------------------------------------------------------------ sidebar End --------------------------------------------------------------------------------------->
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>
