<!-- header start -->
<!-- inner start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>API Maker Features</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-secret.png" alt="Secrets Management" title="Secrets Management">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<!-- inner start -->
<div class="inner-mid-section">
    <div class="container">
        <!-- back page buuton start -->
        <div class="col-12 mt-2 mb-4 text-left">
            <div class="backpage-btn">
                <a class="d-flex flex-nowrap justify-content-start align-items-center" routerLink="/features"><span
                        class="d-flex pe-2"><i class="fa-solid fa-arrow-left arrow-icon"></i></span><span
                        class="d-flex">Back</span></a>
            </div>
        </div>
        <!-- back page buuton End -->
        <!-- page title start -->
        <h2>Secrets <span class="light-text">Management</span></h2>
        <!-- page title End -->
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="row d-flex justify-content-start align-items-start flex-direction">
                    <!------------------------------------------------------------------------------ left start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-9 col-lg-8 col-md-12 col-sm-12 left-section">
                        <!-- content start -->
                        <div class="inn-common-content mb-4">
                            <h3 class="mb-3">A centralized place for all our secret keys.</h3>
                            <div class="col-12 mt-4 mb-4 text-center secrets-cnt-banner">
                            </div>
                            <div class="common-listing pt-3">
                                <ul>
                                    <li>Immediate effect everywhere if we do any change in</li>
                                    <li>database connection string</li>
                                    <li>authorization keys and methods</li>
                                    <li>encryption/decryption algorithm and secret</li>
                                    <li>hashing algorithm and key</li>
                                    <li>and other settings...</li>
                                </ul>
                            </div>
                            <div class="inner-page-cnt-banner inner-small-banner text-center mt-3"><img
                                    src="../../assets/images/secret-content-banner.png"
                                    alt="Centralized place for all our secret keys"
                                    title="Centralized place for all our secret keys"></div>
                            <div class="common-listing pt-3 mt-3">
                                <ul>
                                    <li>We can have multiple secret objects.</li>
                                    <li>Manage separate secrets for DEV & QA & UAT & PROD.</li>
                                    <li>Each developer has a separate secret.</li>
                                    <li>Database connection strings are stored in the secret. While adding/updating
                                        instance you can select that from the dropdown.</li>
                                    <li>Save secret keys like public/private keys of third-party applications,
                                        encryption/decryption algorithm names, and passwords in secret. Users can use
                                        them secretly when require.</li>
                                </ul>
                            </div>
                        </div>
                        <!-- content End -->
                    </div>
                    <!------------------------------------------------------------------------------ left End --------------------------------------------------------------------------------------->

                    <!------------------------------------------------------------------------------ sidebar start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-12 col-sm-12 right-section">
                        <app-features-sidebar></app-features-sidebar>
                    </div>
                    <!------------------------------------------------------------------------------ sidebar End --------------------------------------------------------------------------------------->
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>