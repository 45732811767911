import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

declare const $: any;
declare const document: any;

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [RouterLink, RouterOutlet, RouterLinkActive, CommonModule],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {
    visitors = 0;
    year: number;
    showScrollButton: boolean = false;

    constructor() {
        this.year = new Date().getFullYear();
    }

    ngOnInit() {
        setTimeout(() => {
            this.init();
        }, 1000);
    }

    init() {

        if (!this.visitors) {
            /* visitors js start */
            fetch(`https://savaapi.com/api/am/website/visitors`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json'
                }
            }).then((response) => response.json()).then((resp) => {
                if (resp && resp.success === true && resp.data) {
                    /* console.log(resp.data); */
                    this.visitors = resp.data;
                } else {
                }
            });
            /* visitors js End  */
        }


        if (typeof location !== 'undefined' && location.href.endsWith('/home')) {


            /* year start */
            this.year = new Date().getFullYear();
            /* year End */

            if (typeof $ === 'undefined') return;
            if (typeof document === 'undefined') return;

            /* owl start */
            let owl = $('#owl-carousel2').owlCarousel({
                items: 4,
                loop: true,
                margin: 0,
                autoplay: true,
                autoplayTimeout: 3000,
                autoplayHoverPause: true,
                dots: true,
                responsiveClass: true,
                responsive: {
                    0: {
                        items: 1,
                    },
                    600: {
                        items: 2,
                    },
                    1030: {
                        items: 3,
                    },
                    1280: {
                        items: 3,
                    },
                    1400: {
                        items: 4,
                    },
                    1920: {
                        items: 4,
                    },
                    2600: {
                        items: 4,
                    }
                }
            });
            /* owl End */
            /* owl start */
            let owl2 = $('#owl-carousel3').owlCarousel({
                items: 3,
                loop: true,
                margin: 30,
                autoplay: true,
                autoplayTimeout: 3000,
                autoplayHoverPause: true,
                dots: true,
                responsiveClass: true,
                responsive: {
                    0: {
                        items: 1,
                    },
                    600: {
                        items: 2,
                    },
                    1030: {
                        items: 3,
                    },
                    1280: {
                        items: 3,
                    },
                    1400: {
                        items: 3,
                    },
                    1920: {
                        items: 3,
                    },
                    2600: {
                        items: 3,
                    }
                }
            });
            /* owl End */

            setTimeout(() => {
                if (!document) return;
                const script = document.createElement('script');
                script.src = "https://unpkg.com/typer-dot-js@0.1.0/typer.js";
                document.getElementsByTagName('head')[0].appendChild(script);
            }, 1000);

        }

    }


    /* start */
    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (window.pageYOffset > 100) {
            this.showScrollButton = true;
        } else {
            this.showScrollButton = false;
        }
    }

    /* End */

    scrollToTop() {
        // Smooth scroll to top functionality
        /* window.scrollTo({ top: 0, behavior: 'smooth' }); */
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

}

