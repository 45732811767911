import { Component } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { FeaturesSidebarComponent } from '../features-sidebar/features-sidebar.component';
import { FooterComponent } from '../../footer/footer.component';

@Component({
  selector: 'app-automatic-caching',
  standalone: true,
  imports: [FeaturesSidebarComponent,RouterLink, RouterOutlet, RouterLinkActive,FooterComponent],
  templateUrl: './automatic-caching.component.html',
  styleUrl: './automatic-caching.component.scss'
})
export class AutomaticCachingComponent {

}
