<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>API Maker Features</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-github.png" alt="Git Pull Is Deployment"
                                title="Git Pull Is Deployment">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<div class="inner-mid-section">
    <div class="container">
        <!-- back page buuton start -->
        <div class="col-12 mt-2 mb-4 text-left">
            <div class="backpage-btn">
                <a class="d-flex flex-nowrap justify-content-start align-items-center" routerLink="/features"><span
                        class="d-flex pe-2"><i class="fa-solid fa-arrow-left arrow-icon"></i></span><span
                        class="d-flex">Back</span></a>
            </div>
        </div>
        <!-- back page buuton End -->
        <!-- page title start -->
        <h2>Git Pull Is <span class="light-text">Deployment</span></h2>
        <!-- page title End -->
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="row d-flex justify-content-start align-items-start flex-direction">
                    <!------------------------------------------------------------------------------ left start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-9 col-lg-8 col-md-12 col-sm-12 left-section">
                        <!-- content start -->
                        <!-- browser video start -->
                        <div class="inner-browser-box mb-4">
                            <div class="browser-button w-100 d-flex align-items-center">
                                <span class="button-1"></span>
                                <span class="button-2"></span>
                                <span class="button-3"></span>
                            </div>
                            <div class="browser-cnt">
                                <video controls="">
                                    <source
                                        src="https://images.apimaker.dev/videos/twitter/13_Git_integration_and_deployment.mp4">
                                </video>
                            </div>
                        </div>
                        <!-- browser video End -->
                        <!-- content start -->
                        <div class="inn-common-content mb-4">
                            <div class="inner-page-cnt-banner text-center"><img
                                    src="../../assets/images/deployment-content-banner.png" alt="Easy Deployments"
                                    title="Easy Deployments"></div>
                        </div>
                        <!-- content End -->
                        <div class="inn-common-content mb-4">
                            <div class="col-12">
                                <h3>#Step-by-step deployment</h3>
                            </div>
                            <div class="common-listing mt-3">
                                <ul>
                                    <li>Create branch ex: feature_1 from [DEV/QA/UAT/PROD] within API Maker.</li>
                                    <li>Do your changes in API Maker and commit in feature_1 within API Maker.</li>
                                    <li>Create a pull/merge request from feature_1 to [DEV/QA/UAT/PROD] branch, and
                                        merge it.
                                        <ul>
                                            <li>It will be done in GitHub/GitLab/BitBucket or any git platform, as
                                                convenient.</li>
                                        </ul>
                                    </li>
                                    <li>Go to [DEV/QA/UAT/PROD] server of API Maker and take a pull of
                                        [DEV/QA/UAT/PROD] within API Maker.
                                        <ul>
                                            <li>It’s done now. Changes in the feature_1 branch will be reflected in
                                                the [DEV/QA/UAT/PROD] server immediately.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- content End -->
                    </div>
                    <!------------------------------------------------------------------------------ left End --------------------------------------------------------------------------------------->

                    <!------------------------------------------------------------------------------ sidebar start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-12 col-sm-12 right-section">
                        <app-features-sidebar></app-features-sidebar>
                    </div>
                    <!------------------------------------------------------------------------------ sidebar End --------------------------------------------------------------------------------------->
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>