import { Component } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { FeaturesSidebarComponent } from '../features-sidebar/features-sidebar.component';
import { FooterComponent } from '../../footer/footer.component';

@Component({
  selector: 'app-get-output',
  standalone: true,
  imports: [FeaturesSidebarComponent,RouterLink, RouterOutlet, RouterLinkActive,FooterComponent],
  templateUrl: './get-output.component.html',
  styleUrl: './get-output.component.scss'
})
export class GetOutputComponent {

}
