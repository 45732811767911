import { Component, OnInit } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { FooterComponent } from '../footer/footer.component';

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [RouterLink, RouterOutlet, RouterLinkActive, FooterComponent],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {

    constructor() { }

    scrollToTop() {
        // Smooth scroll to top functionality
        /* window.scrollTo({ top: 0, behavior: 'smooth' }); */
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    htmlToAdd = "curl -fsSL https://apimaker.dev/v1/install.sh > install.sh && bash install.sh --default --version=latest";

    ngOnInit(): void {

    }

    copyContent() {
        const content1 = document.createElement('textarea')
        const currentScrollPosition = window.scrollY;
        content1.value = this.htmlToAdd;
        document.body.appendChild(content1);
        setTimeout(() => {
            content1.focus();
            content1.select();
            document.execCommand('copy');
            document.body.removeChild(content1);
            window.scrollTo(0, currentScrollPosition);
        }, 0);
    }

}
