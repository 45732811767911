<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>API Maker Features</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-third-party.png" alt="Third Party APIs"
                                title="Third Party APIs">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<div class="inner-mid-section">
    <div class="container">
        <!-- back page buuton start -->
        <div class="col-12 mt-2 mb-4 text-left">
            <div class="backpage-btn">
                <a class="d-flex flex-nowrap justify-content-start align-items-center" routerLink="/features"><span
                        class="d-flex pe-2"><i class="fa-solid fa-arrow-left arrow-icon"></i></span><span
                        class="d-flex">Back</span></a>
            </div>
        </div>
        <!-- back page buuton End -->
        <!-- page title start -->
        <h2>Third <span class="light-text">Party APIs</span></h2>
        <!-- page title End -->
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="row d-flex justify-content-start align-items-start flex-direction">
                    <!------------------------------------------------------------------------------ left start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-9 col-lg-8 col-md-12 col-sm-12 left-section">
                        <div class="col-12 mb-4">
                            <h3>Install ready made Third Party APIs with code from API Maker Store</h3>
                        </div>
                        <!-- content start -->
                        <!-- browser video start -->
                        <div class="inner-browser-box mb-4">
                            <div class="browser-button w-100 d-flex align-items-center">
                                <span class="button-1"></span>
                                <span class="button-2"></span>
                                <span class="button-3"></span>
                            </div>
                            <div class="browser-cnt">
                                <video controls="">
                                    <source src="https://images.apimaker.dev/videos/twitter/7_Third_party_APIs.mp4">
                                </video>
                            </div>
                        </div>
                        <!-- browser video End -->
                        <div class="inn-common-content mb-4">
                            <div class="row mt-4 pt-3">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <h4 class="mb-3">S3 bucket APIs example</h4>
                                                        <div class="col-12 mt-2 mb-3 third-partyapi-icon"><img
                                                                src="../../assets/images/third-party-icon-1.svg"
                                                                alt="S3 bucket APIs" title="S3 bucket APIs"></div>
                                                        <div class="common-listing">
                                                            <ul>
                                                                <li>Bucket create/delete</li>
                                                                <li>File upload/download/delete</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <h4 class="mb-3">Google sheet’s APIs example</h4>
                                                        <div class="col-12 mt-2 mb-3 third-partyapi-icon"><img
                                                                src="../../assets/images/third-party-icon-2.svg"
                                                                alt="Google sheet’s APIs"
                                                                title="Google sheet’s APIs"></div>
                                                        <div class="common-listing">
                                                            <ul>
                                                                <li>Sheet create/delete</li>
                                                                <li>Rows insert/update/delete</li>
                                                                <li>Column insert/delete</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <h4 class="mb-3">Image processing example</h4>
                                                        <div class="col-12 mt-2 mb-3 third-partyapi-icon"><img
                                                                src="../../assets/images/third-party-icon-3.png"
                                                                alt="Image processing" title="Image processing">
                                                        </div>
                                                        <div class="common-listing">
                                                            <ul>
                                                                <li>Reduce image size</li>
                                                                <li>Change image dimension</li>
                                                                <li>Change image type</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <h4 class="mb-3">MS Excel utilities example</h4>
                                                        <div class="col-12 mt-2 mb-3 third-partyapi-icon"><img
                                                                src="../../assets/images/third-party-icon-4.png"
                                                                alt="MS Excel utilities" title="MS Excel utilities">
                                                        </div>
                                                        <div class="common-listing">
                                                            <ul>
                                                                <li>Get data in JSON from excel sheet</li>
                                                                <li>Get Excel sheet from JSON data</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <h4 class="mb-3">PDF utilities example</h4>
                                                        <div class="col-12 mt-2 mb-3 third-partyapi-icon"><img
                                                                src="../../assets/images/third-party-icon-5.png"
                                                                alt="PDF utilities" title="PDF utilities"></div>
                                                        <div class="common-listing">
                                                            <ul>
                                                                <li>Get text from PDF</li>
                                                                <li>Create PDF from MS Word file</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <h4 class="mb-3">Mailchimp email APIs</h4>
                                                        <div class="col-12 mt-2 mb-3 third-partyapi-icon"><img
                                                                src="../../assets/images/third-party-icon-6.png"
                                                                alt="Mailchimp email APIs"
                                                                title="Mailchimp email APIs"></div>
                                                        <div class="common-listing">
                                                            <ul>
                                                                <li>Get text from PDF</li>
                                                                <li>Create PDF from MS Word file</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <h4 class="mb-3">Stripe APIs</h4>
                                                        <div class="col-12 mt-2 mb-3 third-partyapi-icon"><img
                                                                src="../../assets/images/third-party-icon-7.png"
                                                                alt="Stripe APIs"
                                                                title="Stripe APIs"></div>
                                                        <div class="common-listing">
                                                            <ul>
                                                                <li>Get text from PDF</li>
                                                                <li>Create PDF from MS Word file</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <h4 class="mb-3">Neo4j database</h4>
                                                        <div class="col-12 mt-2 mb-3 third-partyapi-icon"><img
                                                                src="../../assets/images/third-party-icon-8.png"
                                                                alt="Stripe APIs"
                                                                title="Stripe APIs"></div>
                                                        <div class="common-listing">
                                                            <ul>
                                                                <li>Get text from PDF</li>
                                                                <li>Create PDF from MS Word file</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <!-- content End -->
                    </div>
                    <!------------------------------------------------------------------------------ left End --------------------------------------------------------------------------------------->

                    <!------------------------------------------------------------------------------ sidebar start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-12 col-sm-12 right-section">
                        <app-features-sidebar></app-features-sidebar>
                    </div>
                    <!------------------------------------------------------------------------------ sidebar End --------------------------------------------------------------------------------------->
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
  <!-- inner End -->
  <app-footer></app-footer>