import { Component } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { FeaturesSidebarComponent } from '../features-sidebar/features-sidebar.component';
import { FooterComponent } from '../../footer/footer.component';

@Component({
  selector: 'app-developer-accounts',
  standalone: true,
  imports: [FeaturesSidebarComponent,RouterLink, RouterOutlet, RouterLinkActive,FooterComponent],
  templateUrl: './developer-accounts.component.html',
  styleUrl: './developer-accounts.component.scss'
})
export class DeveloperAccountsComponent {

}
