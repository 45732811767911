<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>Structure Of API</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-structure.png" alt="All Routes Of The Platform Tree"
                                title="All Routes Of The Platform Tree">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<!-- inner start -->
<div class="inner-mid-section">
    <div class="container">
        <h2>All Routes Of <span class="light-text">  The Platform Tree</span></h2>
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="w-100">
                    <img src="../../assets/images/structure_of_apis.svg"
                        alt="Execute all code in secure and well managed sandbox environment"
                        title="Execute all code in secure and well managed sandbox environment">
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>