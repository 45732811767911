import { Component } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { FeaturesSidebarComponent } from '../features-sidebar/features-sidebar.component';
import { FooterComponent } from '../../footer/footer.component';

@Component({
  selector: 'app-secure-sandbox',
  standalone: true,
  imports: [FeaturesSidebarComponent,RouterLink, RouterOutlet, RouterLinkActive,FooterComponent],
  templateUrl: './secure-sandbox.component.html',
  styleUrl: './secure-sandbox.component.scss'
})
export class SecureSandboxComponent {

}
