<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>API Maker Features</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-custom-api.png" alt="Custom APIs Development"
                                title="Custom APIs Development">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<!-- inner start -->
<div class="inner-mid-section">
    <div class="container">
        <!-- back page buuton start -->
        <div class="col-12 mt-2 mb-4 text-left">
            <div class="backpage-btn">
                <a class="d-flex flex-nowrap justify-content-start align-items-center" routerLink="/features"><span
                        class="d-flex pe-2"><i class="fa-solid fa-arrow-left arrow-icon"></i></span><span
                        class="d-flex">Back</span></a>
            </div>
        </div>
        <!-- back page buuton End -->
        <!-- page title start -->
        <h2>Custom <span class="light-text">APIs</span> Development</h2>
        <!-- page title End -->
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="row d-flex justify-content-start align-items-start flex-direction">
                    <!------------------------------------------------------------------------------ left start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-9 col-lg-8 col-md-12 col-sm-12 left-section">
                        <div class="col-12 mb-4">
                            <h3>We can quickly write & test custom APIs to handle complex business scenarios.</h3>
                        </div>
                        <!-- browser video start -->
                        <div class="inner-browser-box mb-4">
                            <div class="browser-button w-100 d-flex align-items-center">
                                <span class="button-1"></span>
                                <span class="button-2"></span>
                                <span class="button-3"></span>
                            </div>
                            <div class="browser-cnt">
                                <video controls="">
                                    <source src="https://images.apimaker.dev/videos/twitter/6_Custom_APIs.mp4">
                                </video>
                            </div>
                        </div>
                        <!-- browser video End -->
                        <!-- content start -->
                        <div class="inn-common-content mb-4">
                            <h3 class="mb-4">In Azure Functions and AWS Lambda</h3>
                            <h4 class="mb-2">We need to</h4>
                            <div class="common-listing mt-3">
                                <ul>
                                    <li>Validate user inputs of request body and request parameters and return a
                                        validation error message in proper format and user language.</li>
                                    <li>Manage database and Redis connectivity manually.</li>
                                    <li>Reset Redis cache on data changes manually.</li>
                                    <li>Write websocket related notifications code manually.</li>
                                    <li>Convert to YAML/XML manually.</li>
                                    <li>Return code error message in user language.</li>
                                    <li>Need to publish every time on change. Need to use command line tools.</li>
                                    <li>Need to pay based on some request count.</li>
                                    <li>Need to provide memory size in advance and use limited memory, because there
                                        are memory limitations.</li>
                                    <li>Need to think about computation, because there are computing time
                                        limitations.</li>
                                    <li>Need to maintain YAML/JSON configuration file.</li>
                                </ul>
                            </div>
                        </div>
                        <!-- content End -->
                        <!-- content start -->
                        <div class="inn-common-content mb-4">
                            <div class="col-12 mb-4">
                                <h3>In API Maker Custom API</h3>
                            </div>
                            <div class="common-listing mt-4">
                                <ul>
                                    <li>Just write your business logic-related code and other things will be handled
                                        by API Maker automatically.</li>
                                </ul>
                            </div>
                        </div>
                        <!-- content End -->
                        <!-- content start -->
                        <div class="inn-common-content  mb-4">
                            <div class="inner-page-cnt-banner text-center">
                                <img src="../../assets/images/custom-api-process-content-banner.png"
                                    alt="API Maker Custom API" title="API Maker Custom API">
                            </div>
                        </div>
                        <!-- content End -->
                        <!-- content start -->
                        <div class="inn-common-content mb-4">

                            <div class="common-listing mt-3">
                                <ul>
                                    <li>Use auto generated APIs, events, utility classes, and system APIs and write
                                        business logic in it.</li>
                                    <li>Download & upload, huge & multiple files using custom APIs.</li>
                                    <li>Share custom APIs with others with proper authentication.</li>
                                    <li>Leverage automatic caching functionality in custom APIs.</li>
                                    <li>Manage [Create, Remove, Activate] API version in a single click.</li>
                                    <li>Add multiple pre and post-hooks for APIs.</li>
                                    <li>Automatic query string and request body validation with internationalization
                                        error massage support.</li>
                                    <li>Insert predefined code samples in one click.</li>
                                    <li>Beautiful error stack trace in response, error highlighted on a specific
                                        line.</li>
                                    <li>Get real-time logs of custom APIs code.</li>
                                    <li>Generate class or interface based on APIs response in single click for 20+
                                        programming languages.</li>
                                    <li>Custom APIs can be good alternative of AWS Lambda, Azure Functions, Edge
                                        Functions.</li>
                                    <li>In Custom APIs, need to write less code compare to AWS Lambda, Azure
                                        Functions and Edge Functions, because of inbuilt system APIs.</li>
                                </ul>
                            </div>
                        </div>
                        <!-- content End -->
                    </div>
                    <!------------------------------------------------------------------------------ left End --------------------------------------------------------------------------------------->

                    <!------------------------------------------------------------------------------ sidebar start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-12 col-sm-12 right-section">
                        <app-features-sidebar></app-features-sidebar>
                    </div>
                    <!------------------------------------------------------------------------------ sidebar End --------------------------------------------------------------------------------------->
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>