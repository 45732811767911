<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>Videos</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-videos.png" alt="FAQ" title="FAQ">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<!-- inner start -->
<div class="inner-mid-section">
    <div class="container">
        <h2>API Maker® <span class="light-text">Videos</span></h2>
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <h3 class="mb-4">Instructions</h3>
                <div class="row mb-3">
                    <!-- video 1 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">API Maker Features quick walkthrough</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/7CMinfkIDCE" target="_blank"><img
                                            src="../../assets/images/videos-thumb/walkthrough.png"
                                            alt="API Maker Features quick walkthrough"
                                            title="API Maker Features quick walkthrough" />
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>
                    <!-- video 1 End -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-2">
                <h3 class="mb-4">Tutorials</h3>
                <div class="row">
                    <!-- video 2 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Use Apis Made By API Maker</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/4ss9NKSe7Z4" target="_blank"><img
                                            src="../../assets/images/videos-thumb/use_api_made_by_am.png"
                                            alt="Use Apis Made By API Maker"
                                            title="Use Apis Made By API Maker" />
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 2 End -->
                    <!-- video 3 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Get APIs in detail</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/L8GqKGGEQjo" target="_blank"><img
                                            src="../../assets/images/videos-thumb/get_apis_details.png"
                                            alt="Get APIs in details"
                                            title="Get APIs in details" />
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 3 End -->
                    <!-- video 4 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Save single or multiple</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/UmEVfKf2O6M" target="_blank"><img
                                            src="../../assets/images/videos-thumb/save_single_mutliple.png"
                                            alt="Save single or multiple"
                                            title="Save single or multiple" />
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 4 start -->
                    <!-- video 5 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Master Save</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/hVFvHjcl98c" target="_blank"><img
                                            src="../../assets/images/videos-thumb/master_save.png"
                                            alt="Master Save"
                                            title="Master Save"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 5 start -->
                    <!-- video 6 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Update by ID</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/PhtWr4kVkMQ" target="_blank"><img
                                            src="../../assets/images/videos-thumb/update_by_id.png"
                                            alt="Update by ID"
                                            title="Update by ID"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 6 start -->
                    <!-- video 7 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Remove by ID</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/O_6Yze6ub44" target="_blank"><img
                                            src="../../assets/images/videos-thumb/remove_by-id.png"
                                            alt="Remove by ID"
                                            title="Remove by ID"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 7 start -->
                    <!-- video 8 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Array Operations Mongodb Only</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/08Dhx7-t510" target="_blank"><img
                                            src="../../assets/images/videos-thumb/array_ops.png"
                                            alt="Array Operations Mongodb Only"
                                            title="Array Operations Mongodb Only"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 8 start -->
                    <!-- video 9 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Update Many API</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/iRq6EumX83A" target="_blank"><img
                                            src="../../assets/images/videos-thumb/update_many.png"
                                            alt="Update Many API"
                                            title="Update Many API"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 9 start -->
                    <!-- video 10 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Remove By Query</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/ScgJjLqxTvU" target="_blank"><img
                                            src="../../assets/images/videos-thumb/remove_by_query.png"
                                            alt="Remove By Query"
                                            title="Remove By Query"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 10 start -->
                    <!-- video 11 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Count and Distinct and Distinct With Query</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/OWCEHr_KWQc" target="_blank"><img
                                            src="../../assets/images/videos-thumb/count_distinct_distinct_with_query.png"
                                            alt="Count and Distinct and Distinct With Query"
                                            title="Count and Distinct and Distinct With Query"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 11 start -->
                    <!-- video 12 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Deep Populate Feature In Detail</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/Jhy35PXJl-4" target="_blank"><img
                                            src="../../assets/images/videos-thumb/deep_populate.png"
                                            alt="Deep Populate Feature In Detail"
                                            title="Deep Populate Feature In Detail"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 12 start -->
                    <!-- video 13 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Find Join Feature In Detail</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/w4sZ7WmNXNE" target="_blank"><img
                                            src="../../assets/images/videos-thumb/find_join.png"
                                            alt="Find Join Feature In Detail"
                                            title="Find Join Feature In Detail"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 13 start -->
                    <!-- video 14 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Custom Apis In Detail</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/08CDuRohL4g" target="_blank"><img
                                            src="../../assets/images/videos-thumb/custom_api.png"
                                            alt="Custom Apis In Detail"
                                            title="Custom Apis In Detail"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 14 start -->
                    <!-- video 15 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Third Party Apis</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/x-G3a5uH1rk" target="_blank"><img
                                            src="../../assets/images/videos-thumb/tp_api.png"
                                            alt="Third Party Apis"
                                            title="Third Party Apis"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 15 start -->
                    <!-- video 16 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Websocket Notifications</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/qf1yycHTkxM" target="_blank"><img
                                            src="../../assets/images/videos-thumb/websocket_notification.png"
                                            alt="Websocket Notifications"
                                            title="Websocket Notifications"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 16 start -->
                    <!-- video 17 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Schedulers</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/lMvd67MiiVU" target="_blank"><img
                                            src="../../assets/images/videos-thumb/schedulers.png"
                                            alt="Schedulers"
                                            title="Schedulers"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 17 start -->
                    <!-- video 18 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Events</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/OLjXUT3811Q" target="_blank"><img
                                            src="../../assets/images/videos-thumb/events.png"
                                            alt="Events"
                                            title="Events"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 18 start -->
                    <!-- video 19 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Logging</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/o6poMlTxkLs" target="_blank"><img
                                            src="../../assets/images/videos-thumb/logging.png"
                                            alt="Logging"
                                            title="Logging"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 19 start -->
                    <!-- video 20 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Automatic Caching</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/sa0Tm77nAgU" target="_blank"><img
                                            src="../../assets/images/videos-thumb/automatic_caching.png"
                                            alt="Automatic Caching"
                                            title="Automatic Caching"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 20 start -->
                    <!-- video 21 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">System APIs</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/s2oall6K9yU" target="_blank"><img
                                            src="../../assets/images/videos-thumb/system_api.png"
                                            alt="System APIs"
                                            title="System APIs"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 21 start -->
                    <!-- video 22 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Authorization Process In Detail</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/8Zo1doM4sVY" target="_blank"><img
                                            src="../../assets/images/videos-thumb/authorization.png"
                                            alt="Authorization Process In Detail"
                                            title="Authorization Process In Detail"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 22 start -->
                    <!-- video 23 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Headers In Detail</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/t3icgWkcmFU" target="_blank"><img
                                            src="../../assets/images/videos-thumb/headers.png"
                                            alt="Headers In Detail"
                                            title="Headers In Detail"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 23 start -->
                    <!-- video 24 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Call External APIs</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/ztlJn-qe7lc" target="_blank"><img
                                            src="../../assets/images/videos-thumb/call_external.png"
                                            alt="Call External APIs"
                                            title="Call External APIs"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 24 start -->
                    <!-- video 25 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Database Migration</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/06yObrVADd0" target="_blank"><img
                                            src="../../assets/images/videos-thumb/db_migration.png"
                                            alt="Database Migration"
                                            title="Database Migration"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 25 start -->
                    <!-- video 26 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Developer Accounts and Git Integration and Database Name Masking</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/Toq_34dC4Cw" target="_blank"><img
                                            src="../../assets/images/videos-thumb/developer_ac.png"
                                            alt="Developer Accounts and Git Integration and Database Name Masking"
                                            title="Developer Accounts and Git Integration and Database Name Masking"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 26 start -->
                    <!-- video 27 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">I18n Internationalization</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/HBhubqm-5vw" target="_blank"><img
                                            src="../../assets/images/videos-thumb/i18.png"
                                            alt="I18n Internationalization"
                                            title="I18n Internationalization"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 27 start -->
                    <!-- video 28 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">API Testing Environment</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/vv1aTejScSw" target="_blank"><img
                                            src="../../assets/images/videos-thumb/api_testing.png"
                                            alt="API Testing Environment"
                                            title="API Testing Environment"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 28 start -->
                    <!-- video 29 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Autoincrements</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/-fXwRemm4kw" target="_blank"><img
                                            src="../../assets/images/videos-thumb/auto_increments.png"
                                            alt="Autoincrements"
                                            title="Autoincrements"/>
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- video 29 start -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <h3 class="mb-4">Installation</h3>
                <div class="row mb-3">
                    <!-- video 1 start -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="video-section youtube-video">
                            <div class="col-12 video-name">Install API Maker in Private VPS</div>
                            <div class="d-block video-box mb-4 mb-lg-4 mb-xl-4 mb-md-4 mb-sm-4">
                                <div class="browser-button w-100 d-flex align-items-center">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="button-3"></span>
                                </div>
                                <div class="video-play mt-1">
                                    <a href="https://youtu.be/h9uq6Z074r4" target="_blank"><img
                                            src="../../assets/images/videos-thumb/install_in_vps.png"
                                            alt="Install API Maker in Private VPS"
                                            title="Install API Maker in Private VPS" />
                                        <div class="video-icon"><img src="../../assets/images/youtube.png" alt="Play Video"
                                                title="Play Video" /></div>
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>
                    <!-- video 1 End -->
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>
