<!-- footer start -->
<div class="footer-section">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="row">
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                        <div class="footer-logo">
                            <a routerLink="/home" (click)="scrollToTop()">
                                <img src="../../assets/images/footer_logo.svg" alt="API Maker® - New era of backend begins" title="API Maker® - New era of backend begins">
                            </a>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4">
                        <div class="footer-box">
                            <h4>Company</h4>
                            <ul>
                                <li (click)="scrollToTop()"><a routerLink="/privacy-policy" (click)="scrollToTop()">Privacy Policy</a></li>
                                <li (click)="scrollToTop()"><a routerLink="/faq" (click)="scrollToTop()">FAQs</a></li>
                                <li (click)="scrollToTop()"><a routerLink="/tearms" (click)="scrollToTop()">Teams</a></li>
                                <li (click)="scrollToTop()"><a routerLink="/contact" (click)="scrollToTop()">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4">
                        <div class="footer-box">
                            <h4>Product</h4>
                            <ul>
                                <li (click)="scrollToTop()"><a routerLink="/platform-tree" (click)="scrollToTop()">Tree of APIs path</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4">
                        <div class="footer-box  mb-award-box">
                            <div class="award-box">
                                <img src="../../assets/images/iso_1.png" alt="QUALITY MANAGEMENT SYSTEM ISO 900 9001 CERTIFIED" title="QUALITY MANAGEMENT SYSTEM ISO 900 9001 CERTIFIED">
                            </div>
                            <div class="award-box">
                                <img src="../../assets/images/iso_2.png" alt="ISO" title="ISO">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                        <div class="footer-box">
                            <h4>Contact Us</h4>
                            <div class="w-100 d-flex flex-nowrap gap-2 align-items-start address-box">
                                <div class="address-icon d-flex mt-1"><i class="fa-solid fa-location-dot"></i></div>
                                <div class="address-text d-flex"> <a href="https://maps.app.goo.gl/2mtPQnGs2uFxA6SV8"
                                        target="_blank">Office 4 & 6, Gangotri Shopping Center, New Ranip,
                                        Ahmedabad, Gujarat, India - 382480</a></div>
                            </div>
                            <div class="mt-3 w-100 d-flex flex-nowrap gap-2 align-items-start address-box">
                                <div class="address-icon d-flex mt-1"><i class="fa-solid fa-envelope"></i></div>
                                <div class="address-text d-flex"><a
                                        href="mailto: contact@apimaker.dev">contact&#64;apimaker.dev</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="social-bg text-center">
    <ul>
        <li><a href="https://x.com/i/flow/login?redirect_after_login=%2Fapi_maker" aria-label="twitter"
                target="_blank"><i class="fa-brands fa-x-twitter"></i></a></li>
        <li><a href="https://www.youtube.com/@api_maker" aria-label="youtube" target="_blank"><i
                    class="fa-brands fa-youtube"></i></a>
        </li>
        <li><a href="https://www.linkedin.com/company/api-maker/" aria-label="linkedin" target="_blank"><i
                    class="fa-brands fa-linkedin"></i></a></li>
        <li><a href="https://dev.to/apimaker" aria-label="dev" target="_blank"><i class="fa-brands fa-dev"></i></a></li>
        <li><a href="https://www.facebook.com/people/API-Maker/100066612363965/" aria-label="facebook"
                target="_blank"><i class="fa-brands fa-facebook"></i></a></li>
    </ul>
</div>
<div class="copyright-bg">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="row">
                    <div
                        class="col-xl-4 col-lg-4 col-md-4 col-sm-4 text-center text-xl-left text-lg-left text-md-left text-sm-center">
                        <div class="copyright-text">API Maker®. All rights reserved {{ year }}</div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 text-center">
                        <div class="copyright-text">Visitors: <span id="counter">{{visitors}}</span></div>
                    </div>
                    <div
                        class="col-xl-4 col-lg-4 col-md-4 col-sm-4 text-center text-xl-right text-lg-right text-md-right text-sm-center">
                        <div class="copyright-text">Developed by : SAVA - ISO 9001 Certified Company</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- footer End -->
<button id="scroll" *ngIf="showScrollButton" (click)="scrollToTop()"><img src="../../assets/images/scroll-icon.png"
        alt="Page Scroll" title="Page Scroll"></button>
