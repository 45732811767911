<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>How can we help you?</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-contact.png" alt="Contact us" title="Contact us">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<div class="inner-mid-section">
    <div class="container">
        <h2>Contact <span class="light-text">us</span></h2>
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5 contact-section">
                <div class="row d-flex justify-content-start align-items-start">
                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="contact-form-left">
                            <h3><span class="light-text">Do you have any questions?</span> <span class="d-block">Please
                                    do not hesitate to contact
                                    us directly. Our team will come back to you within a matter of hours to help
                                    you.</span></h3>
                            <div class="company-address mt-5">
                                <h3>Contact <span class="light-text">Information</span></h3>
                                <div class="company-address-box mt-2 w-100">
                                    <div class="w-100 d-flex align-items-start">
                                        <div class="icon-box mt-1"> <img src="../../assets/images/contact-location-icon.png"
                                                alt="Location" title="Location">
                                        </div>
                                        <div class="contact-info"> <a
                                            href="https://maps.app.goo.gl/2mtPQnGs2uFxA6SV8" target="_blank">Office 4 & 6, Gangotri Shopping Center, New Ranip,
                                            Ahmedabad, Gujarat, India - 382480</a> </div>
                                    </div>
                                </div>
                                <div class="company-address-box w-100">
                                    <div class="w-100 d-flex align-items-start">
                                        <div class="icon-box"> <img src="../../assets/images/contact-phone-icon.png" alt="Phone"
                                                title="Phone"></div>
                                        <div class="contact-info"><a href="tel:+918320789882">+91-832-078-9882 </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="company-address-box w-100">
                                    <div class="w-100 d-flex align-items-start">
                                        <div class="icon-box"> <img src="../../assets/images/contact-email-icon.png" alt="Email"
                                                title="Email"></div>
                                        <div class="contact-info"><a
                                                href="mailto:contact@apimaker.dev">contact&#64;apimaker.dev</a></div>
                                    </div>
                                </div>
                                <div class="company-address-box w-100">
                                    <div class="w-100 d-flex align-items-start">
                                        <div class="icon-box"> <img src="../../assets/images/contact-whatsapp.png" alt="Whatsup"
                                                title="Whatsup">
                                        </div>
                                        <div class="contact-info"><a href="https://wa.me/8320789882"
                                                target="_blank">whatsapp Chat</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="contact-form-right">
                            <div class="contact-form w-100">
                                <form  #register="ngForm" (ngSubmit)="submitForm()">
                                    <input type="hidden" data-form-email="true">
                                    <div class="w-100 mb-4">
                                        <input type="text" #name="ngModel"  name="name" [(ngModel)]="userData.name" placeholder="Name *" class="form-control custom-input form-control-lg" required>
                                    </div>
                                    <div class="w-100 mb-4">
                                        <input type="email" #email="ngModel"  name="email" [(ngModel)]="userData.email" class="form-control custom-input form-control-lg"  placeholder="Email *" required>
                                    </div>
                                    <div class="w-100 mb-4">
                                        <input type="text" #companyname="ngModel" name="companyname" [(ngModel)]="userData.companyname" class="form-control custom-input form-control-lg"  placeholder="Company Name *" required>
                                    </div>
                                    <div class="w-100 mb-4">
                                        <input type="number" #mobile="ngModel"   name="mobile" [(ngModel)]="userData.mobile" class="form-control custom-input form-control-lg" placeholder="Mobile Number *" required minlength="10" maxlength="10">
                                    </div>
                                    <div class="w-100 mb-4">
                                        <input type="text" #subject="ngModel" name="subject" [(ngModel)]="userData.subject" class="form-control custom-input form-control-lg"  placeholder="Subject *" required>
                                    </div>
                                    <div class="w-100 mb-4">
                                        <textarea name="message" #message="ngModel" [(ngModel)]="userData.message" class="form-control custom-textare" placeholder="Message *" rows="4" required></textarea>
                                    </div>
                                    <div class="w-100 mb-4 bg-white p-2 border-danger rounded"><span class="text-danger">Please Fill The Required Fields</span></div>
                                    <div class="w-100 d-flex flex-wrap">
                                        <span class="d-flex me-3 mb-2"><button type="submit" [disabled]="register.invalid" class="btn common-btn">Submit</button></span>
                                        <span class="d-flex me-3 mb-2"><button type="reset" class="btn common-btn">Reset</button></span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>
