<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>API Maker Features</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-sandbox.png" alt="Secure Sandbox"
                                title="Secure Sandbox">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<!-- inner End -->
<div class="inner-mid-section">
    <div class="container">
        <!-- back page buuton start -->
        <div class="col-12 mt-2 mb-4 text-left">
            <div class="backpage-btn">
                <a class="d-flex flex-nowrap justify-content-start align-items-center" routerLink="/features"><span
                        class="d-flex pe-2"><i class="fa-solid fa-arrow-left arrow-icon"></i></span><span
                        class="d-flex">Back</span></a>
            </div>
        </div>
        <!-- back page buuton End -->
        <!-- page title start -->
        <h2>Secure <span class="light-text">Sandbox</span></h2>
        <!-- page title End -->
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="row d-flex justify-content-start align-items-start flex-direction">
                    <!------------------------------------------------------------------------------ left start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-9 col-lg-8 col-md-12 col-sm-12 left-section">
                        <!-- content start -->
                        <div class="inn-common-content mb-4">
                            <h3 class="mb-3">Execute all code in a secure and well-managed sandbox environment</h3>
                            <div class="col-12 mt-4 mb-4 inner-page-cnt-banner inner-small-banner text-center mt-3"><img
                                    src="../../assets/images/sandbox-content-banner.png"
                                    alt="Execute all code in secure and well managed sandbox environment"
                                    title="Execute all code in secure and well managed sandbox environment">
                            </div>
                            <div class="common-listing pt-3">
                                <ul>
                                    <li>Code will run in the secure sandbox without affecting other system resources
                                        and APIs</li>
                                    <li>The code should complete its execution in a certain time.</li>
                                    <li>User can adjust code run time by providing in request header.</li>
                                    <li>Custom APIs, Utility class, Event, Data migration, and Scheduler code run in the
                                        secure sandbox.</li>
                                    <li>API Maker generates multiple secure sandboxes as a requirement.</li>
                                </ul>
                            </div>
                        </div>
                        <!-- content End -->
                    </div>
                    <!------------------------------------------------------------------------------ left End --------------------------------------------------------------------------------------->

                    <!------------------------------------------------------------------------------ sidebar start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-12 col-sm-12 right-section">
                        <app-features-sidebar></app-features-sidebar>
                    </div>
                    <!------------------------------------------------------------------------------ sidebar End --------------------------------------------------------------------------------------->
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>