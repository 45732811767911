import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'app-features-sidebar',
    standalone: true,
    imports: [RouterLink, RouterOutlet, RouterLinkActive],
    templateUrl: './features-sidebar.component.html',
    styleUrl: './features-sidebar.component.scss'
})
export class FeaturesSidebarComponent {
    sticky: boolean = false;

    @ViewChild('stickyMenu')
    menuElement!: ElementRef;

    menuPosition: any;

    ngAfterViewInit() {
        this.menuPosition = this.menuElement.nativeElement.offsetTop
    }

    @HostListener('window:scroll', ['$event'])
    handleScroll() {
        const windowScroll = window.pageYOffset;
        if (windowScroll >= this.menuPosition) {
            this.sticky = true;
        } else {
            this.sticky = false;
        }

    }

}
