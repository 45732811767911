import { Component } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { FormGroup,FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { UserService } from '../user.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [FooterComponent,FormsModule,ReactiveFormsModule, CommonModule],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent {
  
  userData:any = { 
    name: '',
    email: '',
    companyname: '',
    mobile: '',
    subject: '',
    message: '',
  };
name: any;

  constructor(
    private UserService: UserService){ }

    submitForm() {
      this.UserService.addUser(this.userData)
        .subscribe(
          response => {
            /* console.log('User added successfully:', response); */
            // Handle success (e.g., show success message, clear form)
            this.userData = {  }; // Clear form fields
          },
          error => {
            console.error('Error adding user:', error);
            // Handle error (e.g., show error message)
          }
        );

        alert("Thank you for contacting API Maker. We will contact you soon...!!!")
    }


  }
