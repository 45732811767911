<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>API Maker Features</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-json.png" alt="Get output in JSON/YAML/XML from any API response" title="Get output in JSON/YAML/XML from any API response">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<div class="inner-mid-section">
    <div class="container">
        <!-- back page buuton start -->
        <div class="col-12 mt-2 mb-4 text-left">
            <div class="backpage-btn">
                <a class="d-flex flex-nowrap justify-content-start align-items-center" routerLink="/features"><span
                        class="d-flex pe-2"><i class="fa-solid fa-arrow-left arrow-icon"></i></span><span
                        class="d-flex">Back</span></a>
            </div>
        </div>
        <!-- back page buuton End -->
        <!-- page title start -->
        <h2> JSON/YAML/XML <span class="light-text">Output Support</span></h2>
        <!-- page title End -->
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="row d-flex justify-content-start align-items-start flex-direction">
                    <!------------------------------------------------------------------------------ left start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-9 col-lg-8 col-md-12 col-sm-12 left-section">
                        <!-- content start -->
                        <!--  start -->
                        <div class="inn-common-content mb-4">
                            <div class="col-12 mb-3">
                                <h3>Get output in JSON/YAML/XML from any API response</h3>
                            </div>
                            <div class="common-listing">
                                <ul>
                                    <li>API Maker is capable of converting API responses in JSON or YAML or XML
                                        format automatically by just passing the return type in the header.</li>
                                    <li>Response of autogenerated API, custom API, or third-party API will be
                                        converted to YAML or XML automatically if the user passes</li>
                                    <li>“x-am-content-type-response” header value in the request.</li>
                                </ul>
                            </div>
                            <div class="row mt-4 pt-3">
                                <div class="col-md-12">
                                    <div class="row">
                                        <!-- box start -->
                                        <div class="col-md-6 mb-4">
                                            <div class="col-md-12 mb-2">
                                                <h4>JSON</h4>
                                            </div>
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        JSON Response
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code>
&#123;
<span class="json-key">"success"</span>: <span class="json-val">true</span>,
<span class="json-key">"statusCode"</span>: <span class="json-num-val">200</span>,
<span class="json-key">"data"</span>: [
    &#123;
        <span class="json-key">"_id"</span>: <span class="json-val">"5b794fb02fe51f2ead6919da"</span>,
        <span class="json-key">"active"</span>: <span class="json-num-val">1</span>,
        <span class="json-key">"updatedAt"</span>: <span class="json-val">"2018-08-19T11:08:32.5822"</span>,
        <span class="json-key">"createdAt"</span>: <span class="json-val">"2018-08-19T11:08:32.5822"</span>,
        <span class="json-key">"company_name"</span>: <span class="json-val">"prayosha"</span>,
        <span class="json-key">"company_type"</span>: <span class="json-val">"PRODUCTION"</span>,
        <span class="json-key">"__v"</span>: <span class="json-num-val">0</span>
    &#125;
]
&#125;
</code>
                                                        </pre>
                                                    </div>

                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                        <!-- box start -->
                                        <div class="col-md-6 mb-4">
                                            <div class="col-md-12 mb-2">
                                                <h4>XML</h4>
                                            </div>
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        XML Response
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code>
<span class="xml-tag">&lt;?</span><span class="xml-key">xml</span> <span class="json-key">version</span>=<span class="json-val">'1.0'</span><span class="xml-tag">?&gt;</span>
<span class="xml-tag">&lt;</span><span class="xml-key">root</span><span class="xml-tag">&gt;</span>
<span class="xml-tag">&lt;</span><span class="xml-key">success</span><span class="xml-tag">&gt;</span>true<span class="xml-tag">&lt;/</span><span class="xml-key">success</span><span class="xml-tag">&gt;</span>
<span class="xml-tag">&lt;</span><span class="xml-key">statusCode</span><span class="xml-tag">&gt;</span>200<span class="xml-tag">&lt;/</span><span class="xml-key">statusCode</span><span class="xml-tag">&gt;</span>
<span class="xml-tag">&lt;</span><span class="xml-key">data</span><span class="xml-tag">&gt;</span>
    <span class="xml-tag">&lt;</span><span class="xml-key">_el</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">_id</span><span class="xml-tag">&gt;</span>5b794fb02fe51f2ead6919da<span class="xml-tag">&lt;/</span><span class="xml-key">_id</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">active</span><span class="xml-tag">&gt;</span>1<span class="xml-tag">&lt;/</span><span class="xml-key">active</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">updatedAt</span><span class="xml-tag">&gt;</span>2018-08-19T11:08:32.5822<span class="xml-tag">&lt;/</span><span class="xml-key">updatedAt</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">createdAt</span><span class="xml-tag">&gt;</span>2018-08-19T11:08:32.5822<span class="xml-tag">&lt;/</span><span class="xml-key">createdAt</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">company_name</span><span class="xml-tag">&gt;</span>prayosha<span class="xml-tag">&lt;/</span><span class="xml-key">company_name</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">company_type</span><span class="xml-tag">&gt;</span>PRODUCTION<span class="xml-tag">&lt;/</span><span class="xml-key">company_type</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">__v</span><span class="xml-tag">&gt;</span>0<span class="xml-tag">&lt;/</span><span class="xml-key">__v</span><span class="xml-tag">&gt;</span>
    <span class="xml-tag">&lt;/</span><span class="xml-key">_el</span><span class="xml-tag">&gt;</span>
<span class="xml-tag">&lt;/</span><span class="xml-key">data</span><span class="xml-tag">&gt;</span>
<span class="xml-tag">&lt;/</span><span class="xml-key">root</span><span class="xml-tag">&gt;</span>
</code>
                                                            </pre>
                                                    </div>

                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                        <!-- box start -->
                                        <div class="col-md-6 mb-4">
                                            <div class="col-md-12 mb-2">
                                                <h4>YAML</h4>
                                            </div>
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        YAML Response
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code>
<span class="yaml-key">success</span>: <span class="xml-key">true</span>
<span class="yaml-key">statusCode</span>: <span class="json-num-val">200</span>
<span class="yaml-key">data</span>:
    <span class="yaml-key">_id</span>: <span class="json-val">5b794fb02fe51f2ead6919da</span>
    <span class="yaml-key">active</span>: <span class="json-num-val">1</span>
    <span class="yaml-key">updatedAt</span>: <span class="json-num-val">2018-08-19T11:08:32.5822</span>
    <span class="yaml-key">createdAt</span>: <span class="json-num-val">2018-08-19T11:08:32.5822</span>
    <span class="yaml-key">company_name</span>: <span class="json-val">prayosha</span>
    <span class="yaml-key">company_type</span>: <span class="json-val">PRODUCTION</span>
    <span class="yaml-key">__v</span>: <span class="json-num-val">0</span>
</code>
                                                        </pre>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--  End -->
                        <!--  start -->
                        <div class="inn-common-content mb-4">
                            <div class="col-12 mb-3">
                                <h3>User-defined Response pattern [flat/hierarchical]</h3>
                            </div>
                            <div class="common-listing">
                                <ul>
                                    <li> If we want to make the output structure flat, it is also done
                                        automatically. it makes API easily consumable in case of nested structure
                                        response. Just need to pass header “x-am-response-object-type” with the
                                        value “flat”.</li>
                                    <li>It can work in conjuction with “x-am-response-case” and
                                        “x-am-content-type-response” also.</li>
                                    <li>As you can see below example, how easily it can convert N number of nested
                                        levels of response in just simple flat response,</li>
                                    <li>By using this feature, we can easily save response to any SQL database which
                                        does not support nested-level of fields like MongoDB.</li>
                                </ul>
                            </div>
                            <div class="row mt-4 pt-3">
                                <div class="col-md-12">
                                    <div class="row">
                                        <!-- box start -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <div class="col-md-12 mb-2">
                                                <h4>Default Nested Response</h4>
                                            </div>
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        Default Nested Response
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code>
&#123;
<span class="json-key">"success"</span>: <span class="json-val">true</span>,
<span class="json-key">"statusCode"</span>: <span class="json-num-val">200</span>,
<span class="json-key">"data"</span>: &#123;
    <span class="json-key">"hello"</span>: <span class="json-val">"world"</span>,
    <span class="json-key">"level1"</span>: &#123;
        <span class="json-key">"level3"</span>: &#123;
            <span class="json-key">"level3"</span>: &#123;
                <span class="json-key">"name"</span>: <span class="json-val">"API Maker"</span>
            &#125;
        &#125;
    &#125;
&#125;
&#125;
</code>
                                                        </pre>
                                                    </div>

                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                        <!-- box start -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <div class="col-md-12 mb-2">
                                                <h4>Flat Response</h4>
                                            </div>
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        Flat Response
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code class="fw-bold">
&#123;
<span class="json-key">"success"</span>: <span class="json-val">true</span>,
<span class="json-key">"statusCode"</span>: <span class="json-num-val">200</span>,
<span class="json-key">"data"</span>: &#123;
    <span class="json-key">"hello"</span>: <span class="json-val">"world"</span>,
    <span class="json-key">"level1_level2_level3_name"</span>: <span class="json-val">"API Maker"</span>
&#125;
&#125;
</code>
                                                        </pre>
                                                    </div>

                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--  End -->
                        <!--  start -->
                        <div class="inn-common-content mb-4">
                            <div class="col-12 mb-3">
                                <h3>Response case changes automatically</h3>
                            </div>
                            <div class="common-listing">
                                <h4 class="mb-3 mt-4">We can change response data to any case. Just need to pass the
                                    relevant value in the request header “x-am-response-case</h4>
                                <ul>
                                    <li>The example above is the default response of any API</li>
                                    <li>We passed the “x-am-response-case” value as “pascalCase” and it will return
                                        the API response as shown below.</li>
                                </ul>
                                <h4 class="mb-3 mt-4">Below types of response cases are supported</h4>
                                <ul>
                                    <li>camelCase</li>
                                    <li>capitalCase</li>
                                    <li>constantCase</li>
                                    <li>dotCase</li>
                                    <li>headerCase</li>
                                    <li>noCase</li>
                                    <li>paramCase</li>
                                    <li>pascalCase</li>
                                    <li>pathCase</li>
                                    <li>sentenceCase</li>
                                    <li>snakeCase</li>
                                </ul>
                            </div>
                            <div class="row mt-4 pt-3">
                                <div class="col-md-12">
                                    <div class="row">
                                        <!-- box start -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <div class="col-md-12 mb-2">
                                                <h4>Default JSON Response</h4>
                                            </div>
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        JSON Response
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code>
&#123;
<span class="json-key">"success"</span>: <span class="json-val">true</span>,
<span class="json-key">"statusCode"</span>: <span class="json-num-val">200</span>,
<span class="json-key">"data"</span>: [
    &#123;
        <span class="json-key">"_id"</span>: <span class="json-val">"5b794fb02fe51f2ead6919da"</span>,
        <span class="json-key">"active"</span>: <span class="json-num-val">1</span>,
        <span class="json-key">"updatedAt"</span>: <span class="json-val">"2018-08-19T11:08:32.5822"</span>,
        <span class="json-key">"createdAt"</span>: <span class="json-val">"2018-08-19T11:08:32.5822"</span>,
        <span class="json-key">"company_name"</span>: <span class="json-val">"prayosha"</span>,
        <span class="json-key">"company_type"</span>: <span class="json-val">"PRODUCTION"</span>,
        <span class="json-key">"__v"</span>: <span class="json-num-val">0</span>
    &#125;
]
&#125;
</code>
                                                        </pre>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                        <!-- box start -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <div class="col-md-12 mb-2">
                                                <h4>ConstantCase JSON Response</h4>
                                            </div>
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        JSON Response
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code>
&#123;
<span class="json-key">"success"</span>: <span class="json-val">true</span>,
<span class="json-key">"statusCode"</span>: <span class="json-num-val">200</span>,
<span class="json-key">"data"</span>: [
    &#123;
        <span class="json-key">"_ID"</span>: <span class="json-val">"5b794fb02fe51f2ead6919da"</span>,
        <span class="json-key">"ACTIVE"</span>: <span class="json-num-val">1</span>,
        <span class="json-key">"UPDATEDAT"</span>: <span class="json-val">"2018-08-19T11:08:32.5822"</span>,
        <span class="json-key">"CREATEDAT"</span>: <span class="json-val">"2018-08-19T11:08:32.5822"</span>,
        <span class="json-key">"COMPANY_NAME"</span>: <span class="json-val">"prayosha"</span>,
        <span class="json-key">"COMPANY_TYPE"</span>: <span class="json-val">"PRODUCTION"</span>,
        <span class="json-key">"__V"</span>: <span class="json-num-val">0</span>
    &#125;
]
&#125;
</code>
                                                        </pre>
                                                    </div>

                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                        <!-- box start -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <div class="col-md-12 mb-2">
                                                <h4>Default XML Response</h4>
                                            </div>
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        XML Response
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code>
<span class="xml-tag">&lt;?</span><span class="xml-key">xml</span> <span class="json-key">version</span>=<span class="json-val">'1.0'</span><span class="xml-tag">?&gt;</span>
<span class="xml-tag">&lt;</span><span class="xml-key">root</span><span class="xml-tag">&gt;</span>
<span class="xml-tag">&lt;</span><span class="xml-key">success</span><span class="xml-tag">&gt;</span>true<span class="xml-tag">&lt;/</span><span class="xml-key">success</span><span class="xml-tag">&gt;</span>
<span class="xml-tag">&lt;</span><span class="xml-key">statusCode</span><span class="xml-tag">&gt;</span>200<span class="xml-tag">&lt;/</span><span class="xml-key">statusCode</span><span class="xml-tag">&gt;</span>
<span class="xml-tag">&lt;</span><span class="xml-key">data</span><span class="xml-tag">&gt;</span>
    <span class="xml-tag">&lt;</span><span class="xml-key">_el</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">_id</span><span class="xml-tag">&gt;</span>5b794fb02fe51f2ead6919da<span class="xml-tag">&lt;/</span><span class="xml-key">_id</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">active</span><span class="xml-tag">&gt;</span>1<span class="xml-tag">&lt;/</span><span class="xml-key">active</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">updatedAt</span><span class="xml-tag">&gt;</span>2018-08-19T11:08:32.5822<span class="xml-tag">&lt;/</span><span class="xml-key">updatedAt</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">createdAt</span><span class="xml-tag">&gt;</span>2018-08-19T11:08:32.5822<span class="xml-tag">&lt;/</span><span class="xml-key">createdAt</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">company_name</span><span class="xml-tag">&gt;</span>prayosha<span class="xml-tag">&lt;/</span><span class="xml-key">company_name</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">company_type</span><span class="xml-tag">&gt;</span>PRODUCTION<span class="xml-tag">&lt;/</span><span class="xml-key">company_type</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">__v</span><span class="xml-tag">&gt;</span>0<span class="xml-tag">&lt;/</span><span class="xml-key">__v</span><span class="xml-tag">&gt;</span>
    <span class="xml-tag">&lt;/</span><span class="xml-key">_el</span><span class="xml-tag">&gt;</span>
<span class="xml-tag">&lt;/</span><span class="xml-key">data</span><span class="xml-tag">&gt;</span>
<span class="xml-tag">&lt;/</span><span class="xml-key">root</span><span class="xml-tag">&gt;</span>
</code>
                                                            </pre>
                                                    </div>

                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                        <!-- box start -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <div class="col-md-12 mb-2">
                                                <h4>ConstantCase XML Response</h4>
                                            </div>
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        XML Response
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code>
<span class="xml-tag">&lt;?</span><span class="xml-key">xml</span> <span class="json-key">version</span>=<span class="json-val">'1.0'</span><span class="xml-tag">?&gt;</span>
<span class="xml-tag">&lt;</span><span class="xml-key">root</span><span class="xml-tag">&gt;</span>
<span class="xml-tag">&lt;</span><span class="xml-key">success</span><span class="xml-tag">&gt;</span>true<span class="xml-tag">&lt;/</span><span class="xml-key">success</span><span class="xml-tag">&gt;</span>
<span class="xml-tag">&lt;</span><span class="xml-key">statusCode</span><span class="xml-tag">&gt;</span>200<span class="xml-tag">&lt;/</span><span class="xml-key">statusCode</span><span class="xml-tag">&gt;</span>
<span class="xml-tag">&lt;</span><span class="xml-key">data</span><span class="xml-tag">&gt;</span>
    <span class="xml-tag">&lt;</span><span class="xml-key">_el</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">_ID</span><span class="xml-tag">&gt;</span>5b794fb02fe51f2ead6919da<span class="xml-tag">&lt;/</span><span class="xml-key">_ID</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">ACTIVE</span><span class="xml-tag">&gt;</span>1<span class="xml-tag">&lt;/</span><span class="xml-key">ACTIVE</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">UPDATEDAT</span><span class="xml-tag">&gt;</span>2018-08-19T11:08:32.5822<span class="xml-tag">&lt;/</span><span class="xml-key">UPDATEDAT</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">CREATEDAT</span><span class="xml-tag">&gt;</span>2018-08-19T11:08:32.5822<span class="xml-tag">&lt;/</span><span class="xml-key">CREATEDAT</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">COMPANY_NAME</span><span class="xml-tag">&gt;</span>prayosha<span class="xml-tag">&lt;/</span><span class="xml-key">COMPANY_NAME</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">COMPANY_TYPE</span><span class="xml-tag">&gt;</span>PRODUCTION<span class="xml-tag">&lt;/</span><span class="xml-key">COMPANY_TYPE</span><span class="xml-tag">&gt;</span>
        <span class="xml-tag">&lt;</span><span class="xml-key">__V</span><span class="xml-tag">&gt;</span>0<span class="xml-tag">&lt;/</span><span class="xml-key">__V</span><span class="xml-tag">&gt;</span>
    <span class="xml-tag">&lt;/</span><span class="xml-key">_el</span><span class="xml-tag">&gt;</span>
<span class="xml-tag">&lt;/</span><span class="xml-key">data</span><span class="xml-tag">&gt;</span>
<span class="xml-tag">&lt;/</span><span class="xml-key">root</span><span class="xml-tag">&gt;</span>
</code>
                                                            </pre>
                                                    </div>

                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                        <!-- box start -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                            <div class="col-md-12 mb-2">
                                                <h4>Default YAML Response</h4>
                                            </div>
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        YAML Response
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code class="fw-bold" style="font-size: 12px; margin-left: -37px">
<span class="yaml-key">success</span>: <span class="xml-key">true</span>
<span class="yaml-key">statusCode</span>: <span class="json-num-val">200</span>
<span class="yaml-key">data</span>:
        <span class="yaml-key">_id</span>: <span class="json-val">5b794fb02fe51f2ead6919da</span>
        <span class="yaml-key">active</span>: <span class="json-num-val">1</span>
        <span class="yaml-key">updatedAt</span>: <span class="json-num-val">2018-08-19T11:08:32.5822</span>
        <span class="yaml-key">createdAt</span>: <span class="json-num-val">2018-08-19T11:08:32.5822</span>
        <span class="yaml-key">company_name</span>: <span class="json-val">prayosha</span>
        <span class="yaml-key">company_type</span>: <span class="json-val">PRODUCTION</span>
        <span class="yaml-key">__v</span>: <span class="json-num-val">0</span>
</code>
                                                        </pre>
                                                    </div>

                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                        <!-- box start -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                            <div class="col-md-12 mb-2">
                                                <h4>ConstantCase YAML Response</h4>
                                            </div>
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        YAML Response
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code>
<span class="yaml-key">success</span>: <span class="xml-key">true</span>
<span class="yaml-key">statusCode</span>: <span class="json-num-val">200</span>
<span class="yaml-key">data</span>:
    <span class="yaml-key">_ID</span>: <span class="json-val">5b794fb02fe51f2ead6919da</span>
    <span class="yaml-key">ACTIVE</span>: <span class="json-num-val">1</span>
    <span class="yaml-key">UPDATEDAT</span>: <span class="json-num-val">2018-08-19T11:08:32.5822</span>
    <span class="yaml-key">CREATEDAT</span>: <span class="json-num-val">2018-08-19T11:08:32.5822</span>
    <span class="yaml-key">COMPANY_NAME</span>: <span class="json-val">prayosha</span>
    <span class="yaml-key">COMPANY_TYPE</span>: <span class="json-val">PRODUCTION</span>
    <span class="yaml-key">__V</span>: <span class="json-num-val">0</span>
</code>
                                                        </pre>
                                                    </div>

                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--  End -->
                        <!-- content End -->
                    </div>
                    <!------------------------------------------------------------------------------ left End --------------------------------------------------------------------------------------->

                    <!------------------------------------------------------------------------------ sidebar start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-12 col-sm-12 right-section">
                        <app-features-sidebar></app-features-sidebar>
                    </div>
                    <!------------------------------------------------------------------------------ sidebar End --------------------------------------------------------------------------------------->
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>