<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>API Maker Features</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-logging.png" alt="API Logging" title="API Logging">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<div class="inner-mid-section">
    <div class="container">
        <!-- back page buuton start -->
        <div class="col-12 mt-2 mb-4 text-left">
            <div class="backpage-btn">
                <a class="d-flex flex-nowrap justify-content-start align-items-center" routerLink="/features"><span
                        class="d-flex pe-2"><i class="fa-solid fa-arrow-left arrow-icon"></i></span><span
                        class="d-flex">Back</span></a>
            </div>
        </div>
        <!-- back page buuton End -->
        <!-- page title start -->
        <h2>API <span class="light-text">Logging</span></h2>
        <!-- page title End -->
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="row d-flex justify-content-start align-items-start flex-direction">
                    <!------------------------------------------------------------------------------ left start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-9 col-lg-8 col-md-12 col-sm-12 left-section">
                        <div class="col-12 mb-4">
                            <h3>Profile-based logging for everything</h3>
                        </div>
                        <!-- browser video start -->
                        <div class="inner-browser-box mb-4">
                            <div class="browser-button w-100 d-flex align-items-center">
                                <span class="button-1"></span>
                                <span class="button-2"></span>
                                <span class="button-3"></span>
                            </div>
                            <div class="browser-cnt">
                                <video controls="">
                                    <source src="https://images.apimaker.dev/videos/twitter/11_Logging.mp4">
                                </video>
                            </div>
                        </div>
                        <!-- browser video End -->
                        <!-- content start -->
                        <div class="inn-common-content mb-4">
                            <div class="col-12 international-page-banner text-center"><img
                                    src="../../assets/images/log-profile-content-banner.png"
                                    alt="Profile based logging for everything"
                                    title="Profile based logging for everything"></div>
                            <div class="common-listing mt-4">
                                <ul>
                                    <li>Create multiple log profiles.</li>
                                    <li>Set instances, databases, collections, and generated APIs level selection
                                        for logging. Have the option to save the response in the log.</li>
                                    <li>Select for logging Custom APIs, and System APIs.</li>
                                    <li>API Maker admin user can select the bundle's Third party API, Events, and
                                        its listeners, Schedulers for logging.</li>
                                </ul>
                            </div>
                        </div>
                        <!-- content End -->
                        <!-- content start -->
                        <div class="inn-common-content mb-4">
                            <div class="col-12 mb-4">
                                <h3>To get specific log result apply filter</h3>
                            </div>
                            <div class="col-12 international-page-banner text-center"><img
                                    src="../../assets/images/log-profile-content-banner-2.png"
                                    alt="API Maker Log Table Summary Filter" title="API Maker Log Table Summary Filter">
                            </div>
                            <div class="common-listing mt-4">
                                <ul>
                                    <li>Set filter for instances, databases, collections, and generated APIs level.
                                        To get particular API logs.</li>
                                    <li>The filter can apply to Custom APIs, System APIs, Third-party APIs, Events
                                        and Schedulers too.</li>
                                    <li>Date-wise filter for all logs.</li>
                                </ul>
                            </div>
                        </div>
                        <!-- content End -->
                        <!-- content start -->
                        <div class="inn-common-content mb-4">
                            <div class="col-12 pb-2 international-page-banner text-center"><img
                                    src="../../assets/images/log-profile-content-banner-3.png"
                                    alt="API Maker Log Table Summary Filter" title="API Maker Log Table Summary Filter">
                            </div>
                            <div class="col-12  mt-4 mb-3">
                                <h3>The below properties can be checked in logs.</h3>
                            </div>
                            <div class="common-listing">
                                <ul>
                                    <li>API request details like body, params, query params, and headers.</li>
                                    <li>Response body</li>
                                    <li>Time is taken by API</li>
                                    <li>API status code</li>
                                    <li>View separated logs for each API call.</li>
                                </ul>
                            </div>
                            <div class="col-12 mb-3 mt-4">
                                <h3>From the logs , the user can find</h3>
                            </div>
                            <div class="common-listing">
                                <ul>
                                    <li>Slow APIs</li>
                                    <li>Failed APIs with reason</li>
                                    <li>Apply different types of filters & sorting to logs</li>
                                    <li>Download logs in excel and pdf files</li>
                                </ul>
                            </div>
                        </div>
                        <!-- content End -->
                    </div>
                    <!------------------------------------------------------------------------------ left End --------------------------------------------------------------------------------------->

                    <!------------------------------------------------------------------------------ sidebar start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-12 col-sm-12 right-section">
                        <app-features-sidebar></app-features-sidebar>
                    </div>
                    <!------------------------------------------------------------------------------ sidebar End --------------------------------------------------------------------------------------->
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>