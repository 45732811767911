<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>FAQ</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-faq.png" alt="FAQ" title="FAQ">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<!-- inner start -->
<div class="inner-mid-section">
    <div class="container">
        <h2>Frequently Asked <span class="light-text">Questions</span></h2>
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="faq-section w-100 pb-4">
                    <div class="faq-accordion-list">
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapse1" aria-expanded="false"
                                        aria-controls="flush-collapse1">
                                        Why API Maker is free?
                                    </button>
                                </h2>
                                <div id="flush-collapse1" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="common-listing">
                                            <ul>
                                                <li>We believe backend development framework should be 100% free to
                                                    use for
                                                    everyone.</li>
                                                <li>Very useful to small businesses and students who cannot afford
                                                    paid
                                                    software.</li>
                                                <li>Because it is free, our marketing efforts will be very much
                                                    less, and we
                                                    can easily spread it over developer community.</li>
                                                <li>We are generating revenue by providing support to enterprises.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapse2" aria-expanded="false"
                                        aria-controls="flush-collapse2">
                                        Will API Maker be free in future?
                                    </button>
                                </h2>
                                <div id="flush-collapse2" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="common-listing">
                                            <ul>
                                                <li>Yes, That guarantee is indirectly given by Microsoft & Facebook
                                                    &
                                                    Docker.</li>
                                                <li>API Maker is distributed to its users via NPM & Yarn package
                                                    managers
                                                    and once we publish any package in NPM, it will stay in NPM
                                                    forever. can
                                                    not remove it from NPM even if we want.</li>
                                                <li>NPM is acquired by GitHub in 2020 and GitHub is acquired by
                                                    Microsoft.
                                                </li>
                                                <li>Yarn is Node.js package manager from Facebook.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapse3" aria-expanded="false"
                                        aria-controls="flush-collapse3">
                                        Why not open source?
                                    </button>
                                </h2>
                                <div id="flush-collapse3" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="common-listing">
                                            <ul>
                                                <li>We do not want any modifications or distributions of API Maker
                                                    other
                                                    than company itself, so it will protect the intellectual
                                                    property rights
                                                    of API Maker.</li>
                                                <li>Because the source code is not publicly available and therefore
                                                    less
                                                    exposed to potential attackers.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapse4" aria-expanded="false"
                                        aria-controls="flush-collapse4">
                                        Developer | Client | Company : If we use API Maker, we will be dependent on
                                        it
                                        right?
                                    </button>
                                </h2>
                                <div id="flush-collapse4" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="common-listing">
                                            <ul>
                                                <li> No. API Maker is 100% free.</li>
                                                <li>Other than generated APIs & it’s feature, it is just pure
                                                    JavaScript, so
                                                    any JS developer can work on it.
                                                    <ul>
                                                        <li>We have done lots of documentation.</li>
                                                        <li>We have tutorial videos.</li>
                                                    </ul>
                                                </li>
                                                <li>You will install it on your local/servers, so you will have full
                                                    control
                                                    over it.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapse5" aria-expanded="false"
                                        aria-controls="flush-collapse5">
                                        API Maker is free right now, is it going to be free forever?
                                    </button>
                                </h2>
                                <div id="flush-collapse5" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="common-listing">
                                            <ul>
                                                <li>Yes, we are distributing API Maker versions using NPM, and once you
                                                    publish on NPM you can not remove it from NPM.</li>
                                                <li>Our business model is to gain profit from supporting to enterprises.
                                                </li>
                                                <li>API Maker backend solution will remain free indefinitely.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapse6" aria-expanded="false"
                                        aria-controls="flush-collapse6">
                                        Can we connect to any other database which is not supported out of the box?
                                    </button>
                                </h2>
                                <div id="flush-collapse6" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="common-listing">
                                            <ul>
                                                <li>Yes, you can connect any database you want and work easily with it
                                                    as you can do in Node.js.</li>
                                                <li>You can add any NPM package support and use it in custom APIs.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapse7" aria-expanded="false"
                                        aria-controls="flush-collapse7">
                                        How can we say it is complete backend solution?
                                    </button>
                                </h2>
                                <div id="flush-collapse7" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="common-listing">
                                            <ul>
                                                <li>API Maker gives you tons of features, but it also provides you low
                                                    level features, so you can extend its capabilities.</li>
                                                <li>Examples:
                                                    <ul>
                                                        <li>Using native SQL query feature, you can run any complex SQL
                                                            query over database. You can also execute procedures created
                                                            inside DBMS.</li>
                                                        <li>You can override default Docker file, and you can use any OS
                                                            or any OS packages or any program that is built in any other
                                                            programming languages.</li>
                                                        <li>Generated & created APIs, you can consume from any other
                                                            backend technology also, so you can keep your legacy code
                                                            running and slowly migrate on API Maker.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapse8" aria-expanded="false"
                                        aria-controls="flush-collapse8">
                                        What API Maker can not do?
                                    </button>
                                </h2>
                                <div id="flush-collapse8" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="common-listing">
                                            <ul>
                                                <li>API Maker can not do video and audio streaming.</li>
                                                <li>Things which you should not develop in Node.js, should also not
                                                    develop in API Maker.</li>
                                                <li><strong>Work around:</strong> You can keep that module separate with
                                                    your API Maker backend, and they can easily communicate with each
                                                    other.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapse9" aria-expanded="false"
                                        aria-controls="flush-collapse9">
                                        We are not using freeware or open source product as our clients are not using
                                        it.
                                    </button>
                                </h2>
                                <div id="flush-collapse9" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="common-listing">
                                            <ul>
                                                <li>You can buy support plans, you will get priority support and bug
                                                    fixing of API Maker and upgrade guide and help from our side in
                                                    every step you need.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapse10" aria-expanded="false"
                                        aria-controls="flush-collapse10">
                                        Can we get code of API Maker?
                                    </button>
                                </h2>
                                <div id="flush-collapse10" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="common-listing">
                                            <ul>
                                                <li>Technically you have API Maker code when you install it from NPM, it
                                                    is just bundled & obfuscated.</li>
                                                <li>And technically you don’t need API Maker’s code also, because API
                                                    Maker is just set of auto generated APIs, all other code and
                                                    configurations will be done by you only.</li>
                                                <li>We can not provide original source code of API Maker, but you will
                                                    get all the code and configurations you will build on top of it via
                                                    git commit.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapse11" aria-expanded="false"
                                        aria-controls="flush-collapse11">
                                        How long we will provide updates of API Maker?
                                    </button>
                                </h2>
                                <div id="flush-collapse11" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="common-listing">
                                            <ul>
                                                <li>We are improving API Maker and providing bug fixing and upgrades.
                                                </li>
                                                <li>You can purchase our support plans and support our work to continue
                                                    doing it, and you will get best support from our side also.</li>
                                                <li>API Maker is distributed with public via NPM and once you push in
                                                    NPM, it can never be removed. NPM provides that trust.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapse12" aria-expanded="false"
                                        aria-controls="flush-collapse12">
                                        Do you have any other question?
                                    </button>
                                </h2>
                                <div id="flush-collapse12" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="common-listing">
                                            <ul>
                                                <li>Try reaching out to us <a href="contact-us.html">"Contact Us"</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<!-- footer start -->
<app-footer></app-footer>
<!-- footer End -->
