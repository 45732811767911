<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>API Maker Features</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-i18n.png" alt="Auto Generated APIs" title="Auto Generated APIs">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<div class="inner-mid-section">
    <div class="container">
        <!-- back page buuton start -->
        <div class="col-12 mt-2 mb-4 text-left">
            <div class="backpage-btn">
                <a class="d-flex flex-nowrap justify-content-start align-items-center" routerLink="/features"><span
                        class="d-flex pe-2"><i class="fa-solid fa-arrow-left arrow-icon"></i></span><span
                        class="d-flex">Back</span></a>
            </div>
        </div>
        <!-- back page buuton End -->
        <!-- page title start -->
        <h2>Internationalization <span class="light-text">i18N</span></h2>
        <!-- page title End -->
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="row d-flex justify-content-start align-items-start flex-direction">
                    <!------------------------------------------------------------------------------ left start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-9 col-lg-8 col-md-12 col-sm-12 left-section">
                        <div class="col-12 mb-4">
                            <h3>Multiple Languages Support In Error Messages</h3>
                        </div>
                        <!-- browser video start -->
                        <div class="inner-browser-box mb-4">
                            <div class="browser-button w-100 d-flex align-items-center">
                                <span class="button-1"></span>
                                <span class="button-2"></span>
                                <span class="button-3"></span>
                            </div>
                            <div class="browser-cnt">
                                <video controls="">
                                    <source
                                        src="https://images.apimaker.dev/videos/twitter/12_Internationalization_error_messages.mp4">
                                </video>
                            </div>
                        </div>
                        <!-- browser video End -->
                        <!-- content start -->
                        <div class="inn-common-content mb-4">
                            <div class="inner-page-cnt-banner text-center"><img
                                    src="../../assets/images/internationalization-content-banner.png" alt="Error Message"
                                    title="Internationalization i18N"></div>
                        </div>
                        <!-- content End -->
                        <!-- content start -->
                        <div class="inn-common-content mb-4">
                            <div class="common-listing">
                                <ul>
                                    <li>Amazingly, we can get backend error messages in any user language and
                                        directly show them to the user in UI, so the user can take appropriate
                                        actions.</li>
                                    <li>Set symbols, and special characters, as a response error message.</li>
                                    <li>Provide the internationalization name in the header, and you will get an
                                        error message in your provided language.</li>
                                    <li>No need to restart the project, just change the error message, and it will
                                        be reflected immediately.</li>
                                </ul>
                            </div>
                        </div>
                        <!-- content End -->
                    </div>
                    <!------------------------------------------------------------------------------ left End --------------------------------------------------------------------------------------->

                    <!------------------------------------------------------------------------------ sidebar start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-12 col-sm-12 right-section">
                        <app-features-sidebar></app-features-sidebar>
                    </div>
                    <!------------------------------------------------------------------------------ sidebar End --------------------------------------------------------------------------------------->
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>