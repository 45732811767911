<!-- Menu start -->
<div class="container-fluid menu-section fixed-top px-0">
    <div class="col-md-12">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
            <div class="container">
                <div class="logo-section d-flex">
                    <a class="navbar-brand logo-img inline-block" routerLink="/home"><img src="../../assets/images/logo.svg"
                            alt="API Maker® - New era of backend begins" title="API Maker® - New era of backend begins"></a>
                   <span class="logo-punchline d-flex text-start bg-dark">New era of backend begins...</span>
                </div>
                <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel">
                    <div class="offcanvas-header">
                        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                            <li (click)="scrollToTop()" class="nav-item">
                                <a routerLink="/home" data-bs-dismiss="offcanvas" class="nav-link" routerLinkActive="active">Home</a>
                            </li>
                            <li (click)="scrollToTop()" class="nav-item">
                                <a routerLink="/features" data-bs-dismiss="offcanvas" class="nav-link" routerLinkActive="active">Features</a>
                            </li>
                            <li (click)="scrollToTop()" class="nav-item">
                                <a routerLink="/video" data-bs-dismiss="offcanvas" class="nav-link" routerLinkActive="active">Videos</a>
                            </li>
                            <li (click)="scrollToTop()" class="nav-item">
                                <a routerLink="/about" data-bs-dismiss="offcanvas" class="nav-link" routerLinkActive="active">About</a>
                            </li>
                            <li (click)="scrollToTop()" class="nav-item">
                                <a class="nav-link"  target="_blank" href="https://docs.apimaker.dev/">Docs</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>
<!-- Menu End -->
<router-outlet></router-outlet>
