import { Component } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { FeaturesSidebarComponent } from '../features-sidebar/features-sidebar.component';
import { FooterComponent } from '../../footer/footer.component';

@Component({
  selector: 'app-single-sign',
  standalone: true,
  imports: [FeaturesSidebarComponent,RouterLink, RouterOutlet, RouterLinkActive,FooterComponent],
  templateUrl: './single-sign.component.html',
  styleUrl: './single-sign.component.scss'
})
export class SingleSignComponent {

}
