<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>About Us</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-aboutus.png" alt="About Us" title="About Us">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<!-- inner start -->
<div class="inner-mid-section">
    <div class="container">
        <h2>About The <span class="light-text"> Company!</span></h2>
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 about-section  mt-5">
                <div class="row mb-4">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div class="w-100 mb-2">
                            <div class="company-logo">
                                <a href="https://savainfosystems.com" target="_blank"><img
                                        src="../../assets/images/sava-logo.png" alt="Sava Info Systems"
                                        title="Sava Info Systems"></a>
                            </div>
                        </div>

                        <div class="w-100">
                            API Maker is well funded project, developed and maintained by <a
                                href="https://savainfosystems.com" target="_blank">SAVA Info Systems Private Limited</a>
                            and being distributed using <a
                                href="https://www.npmjs.com/package/@sava-info-systems/api-maker"
                                target="_blank">NPM</a>.
                        </div>

                        <div class="mt-2">
                            We started API Maker in 9th June 2019, to prevent common repetitive tasks in backend
                            development and to finish projects before time, turns out we could do much more than
                            that.<br />
                            After 4 years of hard work, we did our first public appearance in September 2023. We have
                            made API Maker freeware so everyone can take benefit from it and can develop more robust and
                            maintainable softwares in short time saving time and money.<br />
                        </div>

                        <div class="mt-2">
                            We are team of ~10 people having different skills. We are our first user of API Maker, and
                            we have been developing custom softwares using API Maker since some time and we are really
                            enjoying our backend development. We can't imagine our software development without API
                            Maker. Once you use that, there is no going back.<br />
                        </div>

                        <div class="mt-2">
                            API Maker is free and it will stay free forever. To make this claim more strong we are
                            distributing it using <a href="https://www.npmjs.com/package/@sava-info-systems/api-maker"
                                target="_blank">NPM</a> so even if we want we can not remove API Maker after publishing
                            it over NPM.
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 ">
                        <div class="about-company-banner">
                            <img src="../../assets/images/about-content-banner.jpg" alt="SAVA Info Systems Private - About Us" title="SAVA Info Systems Private - About Us">
                        </div>

                    </div>
                </div>

                <div class="row mb-4 mt-2">
                    <div class="col-12">
                        <h3 class="mb-3">Our <span class="light-text"> Mission</span></h3>
                        <div class="w-100 inn-common-content">
                            API Maker is 100% free for everyone. It is freeware software and distributed via NPM. By
                            making it freeware we want everyone to use API Maker in their backend development.
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <h3 class="mb-2">Our <span class="light-text"> Leaders</span></h3>
                        <div class="w-100">Mr. Bhavesh Kadiya is CEO and also worked on very crucial parts of API Maker.
                            API Maker is output of his consistent hard work for years.
                        </div>
                        <div class="leaders-section mt-2 pt-2">
                            <div class="row">
                                <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-2 mb-4">
                                    <div class="leaders-box">
                                        <div class="leaders-photo">
                                            <img src="../../assets/images/leaders-2.jpg" alt="Bhavesh Kadiya"
                                                title="Bhavesh Kadiya">
                                        </div>
                                        <div class="leaders-name">
                                            <span class="w-100">Bhavesh Kadiya</span>
                                            <span class="w-100 leaders-post">CEO</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>
