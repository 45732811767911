<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>Terms and Conditions</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-terms-conditions.png" alt="Terms and Conditions"
                                title="Terms and Conditions">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<!-- inner start -->
<div class="inner-mid-section">
    <div class="container">
        <h2 class="text-center">Welcome to <span class="light-text">API Maker!</span></h2>
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="inn-common-content mb-4">
                    <div class="w-100 mb-3 text-uppercase fw-bolder">Important-Read Carefully: Your Use Of The Api
                        Maker
                        Software Is
                        Conditioned Upon Your Compliance And Acceptance Of These Terms. By Utilizing Api Maker
                        Software You Agree To Be Bound By These Terms And Conditions. Do Not Continue To Use The Api
                        Maker Website/Software, If You Do Not Agree To Take All The Terms And Conditions Stated On
                        This Page.</div>
                    <div class="w-100 mb-3">The following terminology applies to these Terms and Conditions, Privacy
                        Statement and Disclaimer Notice, and all Agreements: "Client", "You" and "Your" refers to
                        you, the person/entity logged on this website and compliant with the Company’s terms and
                        conditions. If the Software/Service is being purchased on behalf of an entity by an
                        individual authorized to purchase the Software on behalf of such entity, then "You" also
                        refers to such entity. "The Company", "Ourselves", "We", "Our" and "Us", refers to our
                        Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms
                        refer to the offer, acceptance, and consideration of payment necessary to undertake the
                        process of our assistance to the Client in the most appropriate manner for the express
                        purpose of meeting the Client’s needs in respect of the provision of the Company’s stated
                        services, in accordance with and subject to, prevailing law of the India. Any use of the
                        above terminology or other words in the singular, plural, capitalization, and/or he/she or
                        they, are taken as interchangeable and therefore as referring to the same.</div>
                    <div class="w-100 mb-3"><span class="fw-bolder fs-5">This is a legal agreement between You and
                            API Maker,</span> for use of the API
                        Maker's any service(software) including all types of distributed editions till now and
                        upcoming releases.</div>
                    <h2 class="mt-4 mb-2">Software License</h2>
                    <div class="w-100 mb-3">API Maker grants You a non-transferable right and license to use the
                        Software/Service in accordance with this Agreement.</div>
                    <div class="w-100 mb-3">API Maker does not monitor or observe any application developed using
                        API Maker.</div>
                    <div class="w-100 mb-3">API Maker does not assume liability for any fraud that occurs in/by
                        client software/applications developed using API Maker.</div>
                    <div class="w-100 mb-3">If any unauthorized use of API Maker, we can take legal action.</div>
                    <div class="w-100 mb-3">If you create an account, you are responsible for maintaining the
                        security of your account, and you are fully responsible for all activities that occur under
                        the account. You must immediately notify API Maker of any unauthorized uses of your account
                        or any other breaches of security. API Maker will not be liable for any acts or omissions by
                        you, including any damages of any kind incurred as a result of such acts or omissions.</div>
                    <h2 class="mt-4 mb-2">Software License Restrictions</h2>
                    <div class="w-100 mb-3">Other than using the Software/Service for Yourself and Your Company, and
                        as permitted under the terms and conditions of this Agreement between You and API Maker, You
                        can not:</div>
                    <div class="common-listing">
                        <ul>
                            <li>Reproduce, distribute, or transfer the Software/Service, or portions thereof, to any
                                third party.</li>
                            <li>Sell, rent, lease, assign or sublet the Software/Service or portions thereof.</li>
                            <li>Grant rights to any other person, private company or organization.</li>
                            <li>Use the Software/Service in violation of any country or international law or
                                regulation.</li>
                            <li>As a result, if you break any of the above rules, we can revoke your access at any
                                time or cancelled your licence.</li>
                        </ul>
                    </div>
                    <h2 class="mt-4 mb-2">Content License</h2>
                    <div class="w-100 mb-3">Unless otherwise stated, API Maker and/or its licensors own the
                        intellectual property rights for all material on API Maker. All intellectual property rights
                        are reserved. You may access this from API Maker for your personal use subject to
                        restrictions set in these terms and conditions.</div>
                    <div class="w-100 mb-3">You must not:</div>
                    <div class="common-listing">
                        <ul>
                            <li>Republish material from API Maker</li>
                            <li>Sell, rent, or sublicense material from API Maker</li>
                            <li>Reproduce, duplicate or copy material from API Maker</li>
                            <li>Redistribute content from API Maker</li>
                        </ul>
                    </div>
                    <div class="w-100 mb-3">This Agreement shall begin on the date hereof.</div>
                    <div class="w-100 mb-3">Any users to post and exchange opinions and information in certain areas
                        of the API Maker. API Maker does not filter, edit, publish or review Comments before their
                        presence on the website. Comments do not reflect the views and opinions of API Maker, its
                        agents, and/or affiliates. Comments reflect the views and opinions of the person who posts
                        their views and opinions. To the extent permitted by applicable laws, API Maker shall not be
                        liable for the Comments or any liability, damages, or expenses caused and/or suffered as a
                        result of any use of and/or posting of and/or appearance of the Comments on this website.
                    </div>
                    <div class="w-100 mb-3">API Maker reserves the right to monitor all Comments and to remove any
                        Comments which can be considered inappropriate, offensive, or causes a breach of these Terms
                        and Conditions.</div>
                    <div class="w-100 mb-3">You warrant and represent that:</div>
                    <div class="common-listing mb-3">
                        <ul>
                            <li>You are entitled to post the Comments on our website and have all necessary licenses
                                and consents to do so;</li>
                            <li>The Comments do not invade any intellectual property right, including without
                                limitation copyright, patent, or trademark of any third party;</li>
                            <li>The Comments do not contain any defamatory, libelous, offensive, indecent, or
                                otherwise unlawful material which is an invasion of privacy</li>
                            <li>The Comments will not be used to solicit or promote business or custom or present
                                commercial activities or unlawful activity.</li>
                        </ul>
                    </div>
                    <div class="w-100 mb-3">You hereby grant API Maker a non-exclusive license to use, reproduce,
                        edit and authorize others to use, reproduce and edit any of your Comments in any forms,
                        formats, or media.</div>
                    <h2 class="mt-4 mb-2">Charges</h2>
                    <div class="w-100 mb-3">You agree that API Maker may charge to Your credit card or other payment
                        mechanism selected by You and approved by API Maker, all amounts due and owing for the
                        Software/Service, including subscription fees or any other fee or charge associated with
                        Your use of the Software/Service.</div>
                    <div class="common-listing mb-3">
                        <ul>
                            <li>API Maker may change prices at any time without prior notice.</li>
                            <li>You agree that in the event API Maker is unable to collect the fees owed to API
                                Maker for the Software/Service through Your Account, API Maker may take any other
                                steps it deems necessary to collect such fees from You and that You will be
                                responsible for all costs and expenses incurred by API Maker in connection with such
                                collection activity, including collection fees, court costs, and attorneys' fees.
                                You further agree that API Maker may collect interest at the lesser of 1.5% per
                                month or the highest amount permitted by law on any amounts not paid when due.</li>
                            <li>API Maker may change the wallet rules at any time without prior notice:
                                <ul>
                                    <li>To comply with applicable laws and regulations.</li>
                                    <li>To improve the security of the wallet.</li>
                                    <li>To improve the usability of the wallet.</li>
                                    <li>To make changes to the wallet's features or functionality.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <h2 class="mt-4 mb-2">Proprietary Rights</h2>
                    <div class="w-100 mb-3">API Maker or its licensors own and shall retain all proprietary rights,
                        including all copyright, patent, trade secret, trademark, and all other intellectual
                        property rights, in and to the Software. API Maker shall retain ownership of any
                        suggestions, ideas, enhancement requests, feedback, recommendations, or other information
                        provided by Customer or any other party relating to the Software/Service. Customer
                        acknowledges that the licenses granted under this Agreement do not provide Customer with
                        title to or ownership of the Software/Service, but only a right to use under the terms and
                        conditions of this Agreement. Customer shall not use the Software to (a) build a competitive
                        product or service, or (b) copy any features, functions, or graphics of the Software.</div>
                    <h2 class="mt-4 mb-2">Termination</h2>
                    <div class="w-100 mb-3">You may terminate this Agreement by providing written notice to API
                        Maker via e-mail to contact&#64;apimaker.dev or apimaker.dev&#64;gmail.com. Such termination will be
                        effective on the later of: (a) the last day of the term (if your Agreement has a fixed
                        term), or (b) thirty (30) days after API Maker's receipt of your written termination notice.
                        If You fail to comply with any provision of this Agreement, API Maker may terminate this
                        Agreement immediately and cancelled the licence without notice. All sections of this
                        agreement shall survive any termination of this Agreement. Upon any termination of this
                        Agreement, You must cease any further use of the Software/Service and destroy any copies of
                        associated software/service within Your possession and control. We are not bound to give a
                        refund of any software/service in any circumstances. There is no money-back policy.</div>
                    <h2 class="mt-4 mb-2">Injunctive Relief</h2>
                    <div class="w-100 mb-3">You acknowledge that any use of the Software/Service contrary to this
                        Agreement, or any transfer, sublicensing, copying, or disclosure of technical information or
                        materials related to the Software, may cause irreparable injury to API Maker, its
                        affiliates, suppliers, and any other party authorized by API Maker to resell, distribute, or
                        promote the Software/Service ("Resellers"), and under such circumstances API Maker, its
                        affiliates, suppliers and Resellers will be entitled to equitable relief, without posting a
                        bond or other security, including, but not limited to, preliminary and permanent injunctive
                        relief.</div>
                    <h2 class="mt-4 mb-2">No Warranties</h2>
                    <div class="w-100 mb-3">You understand and agree that the software/service is provided "as is"
                        and api maker its affiliates, suppliers, and resellers expressly disclaim all warranties of
                        any kind, express or implied, including without limitation any warranty of merchantability,
                        fitness for a particular purpose or non-infringement. API Maker, its affiliates, suppliers,
                        and resellers make no warranty or representation regarding the results that may be obtained
                        from the use of the software/service, or that the software/service will meet any user's
                        requirements, or be uninterrupted, timely, secure, or error-free. Use of the
                        software/service is at your sole risk. You will be solely responsible for any damage to you
                        resulting from the use of the software/service. The entire risk arising out of the use or
                        performance of the software/service remains with you. You agree to indemnify, defend and
                        hold harmless API Maker, its affiliates, officers, directors, employees, consultants,
                        agents, suppliers, and Resellers from any third-party claims, liability, damages, and/or
                        costs (including, but not limited to, attorneys fees) arising from Your use of the
                        Software/Service, Your violation of this Agreement, or the infringement or violation by You
                        of any intellectual property or another right of any person or entity. Without limiting the
                        foregoing, the Software/Service is not designed or licensed for use in hazardous
                        environments requiring fail-safe controls, including without limitation operation of nuclear
                        facilities, aircraft navigation/communication systems, air traffic control, and life support
                        or weapons systems. Without limiting the generality of the foregoing, API Maker, its
                        affiliates, suppliers, and resellers specifically disclaim any express or implied warranty
                        of fitness for such purposes.</div>
                    <h2 class="mt-4 mb-2">Limitation Of Liability</h2>
                    <div class="w-100 mb-3">The API Maker is non-refundable under any circumstances once purchased.
                    </div>
                    <div class="w-100 mb-3">API Maker relies on a third-party cloud service provider and is not
                        liable for data, information or any type of loss or downtime that may occur.</div>
                    <div class="w-100 mb-3">API Maker disclaims any liability for the misuse of its application
                        under any circumstances or jurisdictions.</div>
                    <div class="w-100 mb-3">API Maker is not liable for any wrong commitment done by sales or
                        marketing team.</div>
                    <div class="w-100 mb-3">API Maker is not liable for any business loss due to delay by the API
                        Maker.</div>
                    <h2 class="mt-4 mb-2">Miscellaneous</h2>
                    <h3 class="mt-4 mb-2">Choice of Law and Forum</h3>
                    <div class="w-100 mb-3">This Agreement shall be governed by and construed under the laws of the
                        Gujarat state, INDIA, as applied to agreements entered into and to be performed in Gujarat
                        by Gujarat residents. The parties consent to the exclusive jurisdiction and venue of the
                        courts located in and serving Ahmedabad, Gujarat.
                    </div>
                    <h2 class="mt-4 mb-2">Waiver and Severability</h2>
                    <div class="w-100 mb-3">Failure by either party to exercise any of its rights under, or to
                        enforce any provision of, this Agreement will not be deemed a waiver or forfeiture of such
                        rights or ability to enforce such provision. If any provision of this Agreement is held by a
                        court of competent jurisdiction to be illegal, invalid, or unenforceable, that provision
                        will be amended to achieve as nearly as possible the same economic effect of the original
                        provision and the remainder of this Agreement will remain in full force and effect.
                    </div>
                    <h2 class="mt-4 mb-2">General Provisions</h2>
                    <div class="w-100 mb-3">This Agreement embodies the entire understanding and agreement between
                        the parties respecting the subject matter of this Agreement and supersedes any prior
                        understandings and agreements between the parties respecting such subject matter. API Maker
                        may change the terms of this Agreement at any time by posting modified terms on its website.
                        This Agreement has been prepared in the English Language and such version shall be
                        controlled in all respects any non-English version of this Agreement is solely for
                        accommodation purposes. All notices or other correspondence to API Maker under this
                        Agreement must be sent to the address as provided by API Maker for such purpose. Any rights
                        and remedies of API Maker upon Your breach or other default under this Agreement will be
                        deemed cumulative and not exclusive of any other right or remedy conferred by this Agreement
                        or by law or equity on API Maker, and the exercise of any one remedy will not preclude the
                        exercise of any other. The captions and headings appearing in this Agreement are for
                        reference only and will not be considered in construing this Agreement.
                    </div>
                    <h2 class="mt-4 mb-2">Export Restrictions</h2>
                    <div class="w-100 mb-3">You acknowledge that the Software or portion thereof may be subject to
                        the export control laws of the India. You will not export, re-export, divert, transfer or
                        disclose any portion of the Software/Service or any related technical information or
                        materials, directly or indirectly, in violation of any applicable export law or regulation.
                    </div>
                    <h2 class="mt-4 mb-2">Cookies</h2>
                    <div class="w-100 mb-2">We employ the use of cookies. By accessing API Maker, you agreed to use
                        cookies in agreement with the API Maker's Privacy Policy.
                    </div>
                    <div class="w-100 mb-3">Most interactive websites use cookies to let us retrieve the user’s
                        details for each visit. Cookies are used by our website to enable the functionality of
                        certain areas to make it easier for people visiting our website. Some of our
                        affiliate/advertising partners may also use cookies.
                    </div>
                    <h2 class="mt-4 mb-2">Hyperlinking to our Content</h2>
                    <div class="w-100 mb-3">The following organizations may link to our Website without prior
                        written approval:
                    </div>
                    <div class="common-listing mb-3">
                        <ul>
                            <li>Government agencies;</li>
                            <li>Search engines;</li>
                            <li>News organizations;</li>
                            <li>Online directory distributors may link to our Website in the same manner as they
                                hyperlink to the Websites of other listed businesses; and</li>
                            <li>System-wide Accredited Businesses except soliciting non-profit organizations,
                                charity shopping malls, and charity fundraising groups which may not hyperlink to
                                our Website.</li>
                        </ul>
                    </div>
                    <div class="w-100 mb-3">These organizations may link to our home page, to publications, or other
                        Website information so long as the link: (a) is not in any way deceptive; (b) does not
                        falsely imply sponsorship, endorsement, or approval of the linking party and its products
                        and/or services; and (c) fits within the context of the linking party’s site.
                    </div>
                    <div class="w-100 mb-3">We may consider and approve other link requests from the following types
                        of organizations:
                    </div>
                    <div class="common-listing mb-3">
                        <ul>
                            <li>commonly-known consumer and/or business information sources;</li>
                            <li>associations or other groups representing charities;</li>
                            <li>online directory distributors;</li>
                            <li>internet portals;</li>
                            <li>accounting, law, and consulting firms; and</li>
                            <li>educational institutions and trade associations.</li>
                        </ul>
                    </div>
                    <div class="w-100 mb-3">We will approve link requests from these organizations if we decide
                        that: (a) the link would not make us look unfavorably to ourselves or our accredited
                        businesses; (b) the organization does not have any negative records with us; (c) the benefit
                        to us from the visibility of the hyperlink compensates the absence of API Maker, and (d) the
                        link is in the context of general resource information.
                    </div>
                    <div class="w-100 mb-3">These organizations may link to our home page so long as the link: (a)
                        is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or
                        approval of the linking party and its products or services; and (c) fits within the context
                        of the linking party’s site.
                    </div>
                    <div class="w-100 mb-3">If you are one of the organizations listed in paragraph 2 above and are
                        interested in linking to our website, you must inform us by sending an e-mail to
                        contact&#64;apimaker.dev Please include your name, your organization name, and contact
                        information as well as the URL of your site, a list of any URLs from which you intend to
                        link to our Website, and a list of the URLs on our site to which you would like to link.
                        Wait 2-3 weeks for a response.</div>
                    <div class="w-100 mb-3">Approved organizations may hyperlink to our Website as follows:</div>
                    <div class="common-listing mb-3">
                        <ul>
                            <li>By use of our corporate name; or</li>
                            <li>By use of the uniform resource locator being linked to; or</li>
                            <li>By use of any other description of our Website being linked to that makes sense
                                within the context and format of content on the linking party’s site.</li>
                        </ul>
                    </div>
                    <div class="w-100 mb-3">No use of API Maker's logo or other artwork will be allowed for linking
                        absent a trademark license agreement.</div>
                    <div class="w-100 mb-3">No use of SAVA Info Systems PVT. LTD.'s logo or other artwork will be
                        allowed for linking absent a trademark license agreement.</div>
                    <h2 class="mt-4 mb-2">iFrames</h2>
                    <div class="w-100 mb-3">Without prior approval and written permission, you may not create frames
                        around our Webpages that alter in any way the visual presentation or appearance of our
                        Website.</div>
                    <h2 class="mt-4 mb-2">iFrames</h2>
                    <div class="w-100 mb-3">Without prior approval and written permission, you may not create frames
                        around our Webpages that alter in any way the visual presentation or appearance of our
                        Website.</div>
                    <h2 class="mt-4 mb-2">Content Liability</h2>
                    <div class="w-100 mb-3">We shall not be held responsible for any content that appears on your
                        Website. You agree to protect and defend us against all claims that are rising on your
                        Website. No link(s) should appear on any Website that may be interpreted as libelous,
                        obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement
                        or other violation of, any third-party rights.</div>
                    <h2 class="mt-4 mb-2">Your Privacy</h2>
                    <div class="w-100 mb-3">Please read Privacy Policy.</div>
                    <h2 class="mt-4 mb-2">Reservation of Rights</h2>
                    <div class="w-100 mb-3">We reserve the right to request that you remove all links or any
                        particular link to our Website. You approve of immediately removing all links to our Website
                        upon request. We also reserve the right to amend these terms and conditions and its linking
                        policy at any time. By continuously linking to our Website, you agree to be bound to and
                        follow these linking terms and conditions.</div>
                    <h2 class="mt-4 mb-2">Removal of links from our website</h2>
                    <div class="w-100 mb-3">If you find any link on our Website that is offensive for any reason,
                        you are free to contact and inform us at any moment. We will consider requests to remove
                        links but we are not obligated to or so or to respond to you directly.</div>
                    <div class="w-100 mb-3">We do not ensure that the information on this website is correct, we do
                        not warrant its completeness or accuracy; nor do we promise to ensure that the website
                        remains available or that the material on the website is kept up to date.</div>
                    <h2 class="mt-4 mb-2">Disclaimer</h2>
                    <div class="w-100 mb-3">To the maximum extent permitted by applicable law, we exclude all
                        representations, warranties, and conditions relating to our website and the use of this
                        website. Nothing in this disclaimer will:</div>
                    <div class="common-listing mb-3">
                        <ul>
                            <li>limit or exclude our or your liability for death or personal injury;</li>
                            <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;
                            </li>
                            <li>limit any of our or your liabilities in any way that is not permitted under
                                applicable law; or</li>
                            <li>exclude any of our or your liabilities that may not be excluded under applicable
                                law.</li>
                        </ul>
                    </div>
                    <div class="w-100 mb-3">The limitations and prohibitions of liability set in this Section and
                        elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all
                        liabilities arising under the disclaimer, including liabilities arising in contract, in
                        tort, and for breach of statutory duty.</div>
                    <div class="w-100 mb-3">As long as the API Maker and the information data and services on the
                        portal are provided free of charge or paid, we will not be liable for any loss or damage to
                        any nature.</div>
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<!-- footer start -->
<app-footer></app-footer>
<!-- footer End -->