<!-- header start -->
<div class="inner-header-section d-flex align-items-center justify-content-center w-100">
    <div class="inner-banner w-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-left">
                        <h2>API Maker Features</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-banner-right">
                        <div class="inner-banner-img">
                            <img src="../../assets/images/sub-header-database-support.png"
                                alt="8 Database Support Out Of The Box" title="8 Database Support Out Of The Box">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header End -->
<!-- inner start -->
<div class="inner-mid-section">
    <div class="container">
        <!-- back page buuton start -->
        <div class="col-12 mt-2 mb-4 text-left">
            <div class="backpage-btn">
                <a class="d-flex flex-nowrap justify-content-start align-items-center" routerLink="/features"><span
                        class="d-flex pe-2"><i class="fa-solid fa-arrow-left arrow-icon"></i></span><span
                        class="d-flex">Back</span></a>
            </div>
        </div>
        <!-- back page buuton End -->
        <!-- page title start -->
        <h2>8 Database <span class="light-text">Support</span></h2>
        <!-- page title End -->
        <!-- Contact start -->
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                <div class="row d-flex justify-content-start align-items-start flex-direction">
                    <!------------------------------------------------------------------------------ left start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-9 col-lg-8 col-md-12 col-sm-12 left-section">
                        <div class="col-12 mb-4">
                            <h3>8 Database Types Supported In CRUD APIs</h3>
                        </div>
                        <div class="col-12 mb-4">
                            <h4>This deep populate feature is supported in all types of databases and supported in
                                all APIs, which returns data.</h4>
                        </div>
                        <!-- browser video start -->
                        <div class="inner-browser-box mb-4">
                            <div class="browser-button w-100 d-flex align-items-center">
                                <span class="button-1"></span>
                                <span class="button-2"></span>
                                <span class="button-3"></span>
                            </div>
                            <div class="browser-cnt">
                                <video controls="">
                                    <source src="https://images.apimaker.dev/videos/twitter/3_Deep_Populate.mp4">
                                </video>
                            </div>
                        </div>
                        <!-- browser video End -->
                        <!--  start -->
                        <div class="inn-common-content mb-4">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <!-- box start -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="col-12 border-bottom border-light-subtle">
                                                        <h3 class="mb-2 fs-5">MongoDB</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Find data from mongodb
                                                        </h4>
                                                    </div>
                                                    <div
                                                        class="col-12 mt-3 border-bottom border-light-subtle border-opacity-10">
                                                        <h3 class="mb-2 fs-5">MySQL</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Get city data from mysql
                                                        </h4>
                                                    </div>
                                                    <div class="col-12 mt-3 border-bottom border-light-subtle">
                                                        <h3 class="mb-2 fs-5">SQL Server</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Get states data from sql
                                                            server database</h4>
                                                    </div>
                                                    <div class="col-12 mt-3 border-bottom border-light-subtle">
                                                        <h3 class="mb-2 fs-5">Maria DB</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Get countries data from
                                                            maria database</h4>
                                                    </div>
                                                    <div class="col-12 mt-3 border-bottom border-light-subtle">
                                                        <h3 class="mb-2 fs-5">Oracle DB</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Get continent data from
                                                            database</h4>
                                                    </div>
                                                    <div class="col-12 mt-3 border-bottom border-light-subtle">
                                                        <h3 class="mb-2 fs-5">PostgreSQL DB</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Get planets data from
                                                            postgresql database</h4>
                                                    </div>
                                                    <div class="col-12 mt-3 border-bottom border-light-subtle">
                                                        <h3 class="mb-2 fs-5">Ti DB</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Get planets data from TI
                                                            database</h4>
                                                    </div>
                                                    <div class="col-12 mt-3 border-bottom border-light-subtle">
                                                        <h3 class="mb-2 fs-5">Percona XtraDB</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Get planets data from
                                                            Percona Xtra database</h4>
                                                    </div>
                                                    <div class="common-listing mt-3">
                                                        <ul>
                                                            <li>One GET API call can get data from N number of
                                                                different types of databases with N number of data
                                                                complexity.</li>
                                                            <li>Stream APIs can stream huge amount of data.</li>
                                                            <li>In our custom APIs, we can get huge data as one by
                                                                one object so we can process them one by one.</li>
                                                            <li>If caching is enabled in any table/collection, it
                                                                can leverage that and speed up the process.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                        <!-- box start -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        8 Database support request body
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code>
&#123;
    <span class="key">"find"</span>: &#123;
    <span class="key mongodb-prop" >"person_name"</span>: <span class="val val">"Kishan"</span>
    &#125;,
    <span class="key">"deep"</span>: [
    &#123;
        <span class="mongodb-prop key" >"s_key": </span><span class="mongodb-val val" >"street_id",</span>
        <span class="mongodb-prop key" >"t_key": </span><span class="mongodb-val val" >"id",</span>
        <span class="mongodb-prop key" >"t_table": </span><span class="mongodb-val val" >"streets",</span>
        <span class="key">"deep"</span>: [
        &#123;
            <span class="mysql-prop key" >"s_key": </span><span class="mysql-val val" >"area_id",</span>
            <span class="mysql-prop key" >"t_key": </span><span class="mysql-val val" >"id",</span>
            <span class="mysql-prop key" >"t_table": </span><span class="mysql-val val" >"areas",</span>
            <span class="key">"deep"</span>: [
            &#123;
                <span class="sql-server-prop key" >"s_key": </span><span class="sql-server-val val" >"city_id",</span>
                <span class="sql-server-prop key" >"t_key": </span><span class="sql-server-val val" >"id",</span>
                <span class="sql-server-prop key" >"t_table": </span><span class="sql-server-val val" >"cities",</span>
                <span class="key">"deep"</span>: [
                &#123;
                    <span class="maira-db-prop key" >"s_key": </span><span class="maira-db-val val" >"state_id",</span>
                    <span class="maira-db-prop key" >"t_key": </span><span class="maira-db-val val" >"id",</span>
                    <span class="maira-db-prop key" >"t_table": </span><span class="maira-db-val val" >"states",</span>
                    <span class="key">"deep"</span>: [
                    &#123;
                        <span class="oracle-db-prop key" >"s_key": </span><span class="oracle-db-val val" >"country_id",</span>
                        <span class="oracle-db-prop key" >"t_key": </span><span class="oracle-db-val val" >"id",</span>
                        <span class="oracle-db-prop key" >"t_table": </span><span class="oracle-db-val val" >"countries",</span>
                        <span class="key">"deep"</span>: [
                        &#123;
                            <span class="postgresql-db-prop key" >"s_key": </span><span class="postgresql-db-val val" >"continent_id",</span>
                            <span class="postgresql-db-prop key" >"t_key": </span><span class="postgresql-db-val val" >"id",</span>
                            <span class="postgresql-db-prop key" >"t_table": </span><span class="postgresql-db-val val" >"continents",</span>
                            <span class="key">"deep"</span>: [
                            &#123;
                                <span class="ti-db-prop key" >"s_key": </span><span class="ti-db-val val" >"planet_id",</span>
                                <span class="ti-db-prop key" >"t_key": </span><span class="ti-db-val val" >"id",</span>
                                <span class="ti-db-prop key" >"t_table": </span><span class="ti-db-val val" >"planets",</span>
                                <span class="key">"deep"</span>: [
                                &#123;
                                <span class="x-tra-db-prop key" >"s_key": </span><span class="x-tra-db-val val" >"galexy_id",</span>
                                <span class="x-tra-db-prop key" >"t_key": </span><span class="x-tra-db-val val" >"id",</span>
                                <span class="x-tra-db-prop key" >"t_table": </span><span class="x-tra-db-val val" >"galaxies"</span>
                                &#125;
                                ]
                            &#125;
                            ]
                        &#125;
                        ]
                    &#125;
                    ]
                &#125;
                ]
            &#125;
            ]
        &#125;
        ]
    &#125;
    ]
&#125;
</code>
                                                            </pre>
                                                    </div>

                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                        <!-- box start -->
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        Response from 8 different databases
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code>
&#123;
    <span class="key">"person_name"</span>: <span class="val">"Kishan"</span>,
    <span class="key">"street_id"</span>: &#123; <span class="comment">// from MongoDB</span>
        <span class="key">"id"</span>: <span class="num-val">12</span>,
        <span class="key">"street_name"</span>: <span class="val">"TIRUPATI"</span>,
        <span class="key">"area_id"</span>: &#123; <span class="comment">// from MySQL</span>
            <span class="key">"id"</span>: <span class="num-val">24</span>,
            <span class="key">"area_name"</span>: <span class="val">"RANIP"</span>,
            <span class="key">"city_id"</span>: &#123; <span class="comment">// from SQL_SERVER</span>
                <span class="key">"id"</span>: <span class="num-val">382480</span>,
                <span class="key">"city_name"</span>: <span class="val">"AHMEDABAD"</span>,
                <span class="key">"state_id"</span>: &#123; <span class="comment">// from MariaDB</span>
                    <span class="key">"id"</span>: <span class="num-val">91</span>,
                    <span class="key">"state_name"</span>: <span class="val">"GUJARAT"</span>,
                    <span class="key">"country_id"</span>: &#123; <span class="comment">// from Oracle</span>
                        <span class="key">"id"</span>: <span class="num-val">4</span>,
                        <span class="key">"country_name"</span>: <span class="val">"INDIA"</span>,
                        <span class="key">"continent_id"</span>: &#123; <span class="comment">// from PostgreSQL</span>
                            <span class="key">"id"</span>: <span class="num-val">3</span>,
                            <span class="key">"countinent_name"</span>: <span class="val">"ASIA"</span>
                            <span class="key">"planet_id"</span>: &#123; <span class="comment">// from Ti DB</span>
                                <span class="key">"id"</span>: <span class="num-val">2</span>,
                                <span class="key">"planet_name"</span>: <span class="val">"EARTH"</span>
                                <span class="key">"galexy_id"</span>: &#123; <span class="comment">// from Percona XtraDB</span>
                                    <span class="key">"id"</span>: <span class="num-val">1</span>,
                                    <span class="key">"galaxies_name"</span>: <span class="val">"Milky Way"</span>
                                &#125;
                            &#125;
                        &#125;
                    &#125;
                &#125;
            &#125;
        &#125;
    &#125;
&#125;
</code>
                                                            </pre>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--  End -->
                        <div class="col-12 pt-4 mb-4">
                            <h3>Save/Update in 8 databases in one API call</h3>
                        </div>
                        <!-- browser video start -->
                        <div class="inner-browser-box mb-4">
                            <div class="browser-button w-100 d-flex align-items-center">
                                <span class="button-1"></span>
                                <span class="button-2"></span>
                                <span class="button-3"></span>
                            </div>
                            <div class="browser-cnt">
                                <video controls="">
                                    <source src="https://images.apimaker.dev/videos/twitter/5_Master_save.mp4">
                                </video>
                            </div>
                        </div>
                        <!-- browser video End -->
                        <div class="inn-common-content mb-4">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <!-- box start -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="col-12 border-bottom border-light-subtle">
                                                        <h3 class="mb-2 fs-5">MongoDB</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Find data from mongodb
                                                        </h4>
                                                    </div>
                                                    <div
                                                        class="col-12 mt-3 border-bottom border-light-subtle border-opacity-10">
                                                        <h3 class="mb-2 fs-5">MySQL</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Get city data from mysql
                                                        </h4>
                                                    </div>
                                                    <div class="col-12 mt-3 border-bottom border-light-subtle">
                                                        <h3 class="mb-2 fs-5">SQL Server</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Get states data from sql
                                                            server database</h4>
                                                    </div>
                                                    <div class="col-12 mt-3 border-bottom border-light-subtle">
                                                        <h3 class="mb-2 fs-5">Maria DB</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Get countries data from
                                                            maria database</h4>
                                                    </div>
                                                    <div class="col-12 mt-3 border-bottom border-light-subtle">
                                                        <h3 class="mb-2 fs-5">Oracle DB</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Get continent data from
                                                            database</h4>
                                                    </div>
                                                    <div class="col-12 mt-3 border-bottom border-light-subtle">
                                                        <h3 class="mb-2 fs-5">PostgreSQL DB</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Get planets data from
                                                            postgresql database</h4>
                                                    </div>
                                                    <div class="col-12 mt-3 border-bottom border-light-subtle">
                                                        <h3 class="mb-2 fs-5">Ti DB</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Get planets data from TI
                                                            database</h4>
                                                    </div>
                                                    <div class="col-12 mt-3 border-bottom border-light-subtle">
                                                        <h3 class="mb-2 fs-5">Percona XtraDB</h3>
                                                        <h4 class="fw-light fs-6 pb-2 ps-2">Get planets data from
                                                            Percona Xtra database</h4>
                                                    </div>
                                                    <div class="common-listing mt-3">
                                                        <ul>
                                                            <li>One GET API call can get data from N number of
                                                                different typea of databases with N number of data
                                                                complexity.</li>
                                                            <li>Moreover we can stream data also, we can get a huge
                                                                amount of data also.</li>
                                                            <li>In our custom APIs, we can get huge data as one by
                                                                one object so we can process them one by one.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                        <!-- box start -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        Master save request body
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code>
&#123;
    <span class="key mongodb-prop" >"person_name"</span>: <span class="mongodb-val val" >"Kishan"</span>,
    <span class="mongodb-prop key" >"street_id"</span>: &#123; <span class="mongodb-val comment" >// for MongoDB</span>
        <span class="mongodb-prop key" >"id"</span>: <span class="mongodb-val num-val" >12</span>,
        <span class="mongodb-prop key" >"street_name"</span>: <span class="mongodb-val val" >"TIRUPATI"</span>,
        <span class="mysql-prop key" >"area_id"</span>: &#123; <span class="mysql-val comment" >// for MySQL</span>
            <span class="mysql-prop key" >"id"</span>: <span class="mysql-val num-val" >24</span>,
            <span class="mysql-prop key" >"area_name"</span>: <span class="mysql-val val" >"RANIP"</span>,
            <span class="sql-server-prop key" >"city_id"</span>: &#123; <span class="sql-server-val comment" >// for SQL_SERVER</span>
                <span class="sql-server-prop key" >"id"</span>: <span class="sql-server-val num-val" >382480</span>,
                <span class="sql-server-prop key" >"city_name"</span>: <span class="sql-server-val val" >"AHMEDABAD"</span>,
                <span class="maira-db-prop key" >"state_id"</span>: &#123; <span class="maira-db-val comment" >// for MariaDB</span>
                    <span class="maira-db-prop key" >"id"</span>: <span class="maira-db-val num-val" >91</span>,
                    <span class="maira-db-prop key" >"state_name"</span>: <span class="maira-db-val val" >"GUJARAT"</span>,
                    <span class="oracle-db-prop key" >"country_id"</span>: &#123; <span class="oracle-db-val comment" >// for Oracle</span>
                        <span class="oracle-db-prop key" >"id"</span>: <span class="oracle-db-val num-val" >4</span>,
                        <span class="oracle-db-prop key" >"country_name"</span>: <span class="oracle-db-val val" >"INDIA"</span>,
                        <span class="postgresql-db-prop key" >"continent_id"</span>: &#123; <span class="postgresql-db-val comment" >// for PostgreSQL</span>
                            <span class="postgresql-db-prop key" >"id"</span>: <span class="postgresql-db-val num-val" >3</span>,
                            <span class="postgresql-db-prop key" >"continent_name"</span>: <span class="postgresql-db-val val" >"ASIA"</span>
                            <span class="ti-db-prop key" >"planet_id"</span>: &#123; <span class="ti-db-val comment" >// for Ti DB</span>
                                <span class="ti-db-prop key" >"id"</span>: <span class="ti-db-val num-val" >2</span>,
                                <span class="ti-db-prop key" >"planet_name"</span>: <span class="ti-db-val val" >"EARTH"</span>
                                <span class="x-tra-db-prop key" >"galexy_id"</span>: &#123; <span class="x-tra-db-val comment" >// for Percona XtraDB</span>
                                    <span class="x-tra-db-prop key" >"id"</span>: <span class="x-tra-db-val num-val" >1</span>,
                                    <span class="x-tra-db-prop key" >"galaxies_name"</span>: <span class="x-tra-db-val val" >"Milky Way"</span>
                                &#125;
                            &#125;
                        &#125;
                    &#125;
                &#125;
            &#125;
        &#125;
    &#125;
&#125;
</code>
                                                                                   </pre>
                                                    </div>

                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                        <!-- box start -->
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        Master Save Response
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code>
&#123;
<span class="key">"_id"</span>: <span class="val">"6458d476462d139b9181e3d7"</span>,
<span class="key">"person_name"</span>: <span class="val">"Kishan"</span>,
<span class="key">"street_id"</span>: <span class="val">5555</span>
&#125;
</code>
                                                            </pre>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 pt-4 mb-4">
                            <h3>Find-join from any databases in one API call</h3>
                        </div>
                        <!-- browser video start -->
                        <div class="inner-browser-box mb-4">
                            <div class="browser-button w-100 d-flex align-items-center">
                                <span class="button-1"></span>
                                <span class="button-2"></span>
                                <span class="button-3"></span>
                            </div>
                            <div class="browser-cnt">
                                <video controls="">
                                    <source src="https://images.apimaker.dev/videos/twitter/4_Find_join_feature.mp4">
                                </video>
                            </div>
                        </div>
                        <!-- browser video End -->
                        <h4 class="mb-4">Get list of orders of product "iPhone 14 Pro" of users with name "James".
                            Database tables can be in any different type of databases.</h4>
                        <div class="inn-common-content mb-4 database-tables-banner"><img
                                src="../../assets/images/find-join-data-content-banner.png"
                                alt="Find join data relationship" title="Find join data relationship"></div>
                        <!--  start -->
                        <div class="inn-common-content mb-4">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <!-- box start -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        Find-join request body
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code>
&#123;
    <span class="key">"find"</span>: &#123;
        <span class="key">"product_id.name"</span>: <span class="val">"iPhone 14 Pro",</span>
        <span class="key">"user_id.name"</span>: <span class="val">"James"</span>
    &#125;
&#125;
</code>
                                                            </pre>
                                                    </div>

                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->
                                        <!-- box start -->
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                            <!-- browser video start -->
                                            <div class="inner-browser-box">
                                                <div
                                                    class="browser-header small-header d-flex align-items-center justify-content-between">
                                                    <div class="browser-button remove-pd d-flex align-items-center">
                                                        <span class="button-1"></span>
                                                        <span class="button-2"></span>
                                                        <span class="button-3"></span>
                                                    </div>
                                                    <div class="d-flex">
                                                        List of orders
                                                    </div>
                                                </div>
                                                <div class="browser-cnt">
                                                    <div class="browser-code-cnt">
                                                        <pre>
<code>
&#123;
    <span class="key">"success"</span>: <span class="val">true,</span>
    <span class="key">"statusCode"</span>: <span class="val">200</span>,
    <span class="key">"data"</span>: [
        &#123;
            <span class="key">"id"</span>: <span class="val">2</span>,
            <span class="key">"product_id"</span>: <span class="val">1</span>,
            <span class="key">"price"</span>: <span class="val">1200</span>,
            <span class="key">"currency"</span>: <span class="val">"USD"</span>,
            <span class="key">"user_id"</span>: <span class="val">2</span>
        &#125;
    ]
&#125;
</code>
                                                            </pre>
                                                    </div>

                                                </div>
                                            </div>
                                            <!-- browser video End -->
                                        </div>
                                        <!-- box End -->

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--  End -->

                        <!-- content End -->
                    </div>
                    <!------------------------------------------------------------------------------ left End --------------------------------------------------------------------------------------->

                    <!------------------------------------------------------------------------------ sidebar start --------------------------------------------------------------------------------------->
                    <div class="col-12 col-xl-3 col-lg-4 col-md-12 col-sm-12 right-section">
                        <app-features-sidebar></app-features-sidebar>
                    </div>
                    <!------------------------------------------------------------------------------ sidebar End --------------------------------------------------------------------------------------->
                </div>
            </div>
        </div>
        <!-- Contact End -->
    </div>
</div>
<!-- inner End -->
<app-footer></app-footer>