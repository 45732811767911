import { Component } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';


@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [RouterLink, RouterOutlet, RouterLinkActive, NavbarComponent],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {

    scrollToTop() {
        // Smooth scroll to top functionality
        /* window.scrollTo({ top: 0, behavior: 'smooth' }); */
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
}
